import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AppRoute, CustomRoutes, SettingRoutes } from '../../routes';
import Grid from "@mui/material/Grid";
import Novas from '../../assets/images/logo.png';
import Woodstock from '../../assets/images/woodstock-white-extract.png';
import '../../layout/navbar/Navbar.css';
import companyLogo from "../../assets/images/woodstockieNewLogo.jpeg";
import { CognitoUser } from '@aws-amplify/auth';
import { Store } from 'react-notifications-component';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';

function NavBar() {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [notificationID, setNotificationID] = useState("")

  const { isAuthenticated, signOut} = useAuth(); // Use context


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();


  return (

    <AppBar position="fixed" className='navBAR' sx={{ backgroundColor: "#D9D9D9", boxShadow: { xs: "0px 1px 1px 2px rgba(12,12,12,0.2)", md: "none" }, paddingBottom: "4vh" }}>
      <Toolbar disableGutters >
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
            height: "10vh"
          }}
        >
          <IconButton aria-label='logo' sx={{
            edge: { xs: "center", sm: "center", md: "center", lg: "start", xl: "start" },
          }}>
            <img className="companyLogo" src={companyLogo} />
          </IconButton>
        </Typography>


        <Grid container sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: "center" }}>

          <Grid sx={{ display: "flex", margin: "12px", padding: "0px" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ padding: "0px", margin: "0px", float: "none" }}
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {CustomRoutes.map((cRo: AppRoute) => {
                return cRo.NavBarVisibile ? (
                  <MenuItem key={cRo.DisplayName} onClick={() => {
                    navigate(cRo.Navigation);
                    handleCloseNavMenu(); // Close the menu after clicking a navigation item
                  }}>
                    <Typography textAlign="center">{cRo.DisplayName}</Typography>
                  </MenuItem>
                ) : null;
              })}
            </Menu>
          </Grid>

          <Grid sx={{ display: "flex", justifyContent: "center", margin: "auto", alignItems: "center" }}>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
              }}>
              <IconButton aria-label='logo' sx={{ padding: "8px 0px", margin: "auto", verticalAlign: "center", display: "flex", alignItems: "center", position: "absolute", left: "50%", right: "50%", marginTop: { xs: "4vh", sm: "2vh" } }}>
                <img className="companyLogo" src={companyLogo} />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>
        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}


        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' }, justifyContent: "flex-end", marginRight: "2rem" }}>
          {CustomRoutes.map((cRo: AppRoute) => {
            return cRo.NavBarVisibile ?
              <Button
                key={cRo.DisplayName}
                onClick={() => navigate(cRo.Navigation)}
                sx={{
                  my: 2,
                  mx: 1,
                  color: '#666',
                  fontFamily: "Droid Sans 2",
                  fontWeight: 400,
                  fontSize: "1rem",
                  display: 'block',
                  '&:hover': {
                    backgroundColor: "#8DC74A",
                    color: "#000"
                  }
                }}
              >
                {cRo.DisplayName}
              </Button> :
              null
          })}
        </Box>



      </Toolbar>
    </AppBar>
  );
}
export default NavBar;
export const markers = [
    { 
      id: 1,
      markerOffset: -30, 
      name: "Cape Town", 
      coordinates: [18.4241, -33.9249], 
      country_code: "ZAF",
      description: "Cape Town is known for its stunning landscapes, including Table Mountain and beautiful beaches.",
      longDescription: "Cape Town, located at the southern tip of Africa, is a vibrant city with a rich cultural history. The city&rsquo;s iconic Table Mountain provides a dramatic backdrop to its picturesque harbor and white sandy beaches. Visitors can take a cable car to the summit of Table Mountain for breathtaking panoramic views or explore the bustling markets and shops at the V&amp;A Waterfront. Cape Town&rsquo;s history is also deeply connected with Robben Island, where Nelson Mandela was imprisoned for 18 years, now a UNESCO World Heritage site. The city&rsquo;s natural beauty is complemented by its thriving arts and music scene, with numerous galleries, theaters, and live music venues showcasing local talent.",
      imageUrl: "https://example.com/images/cape_town.jpg"
    },
    { 
      id: 2,
      markerOffset: 15, 
      name: "Johannesburg", 
      coordinates: [28.0476, -26.2041], 
      country_code: "ZAF",
      description: "Johannesburg is the largest city in South Africa and a major economic hub.",
      longDescription: "Johannesburg, often referred to as Jozi or Joburg, is a bustling metropolis and the economic heart of South Africa. The city is a dynamic blend of old and new, with modern high-rises and sprawling suburbs coexisting with historic sites and cultural landmarks. The Apartheid Museum provides an in-depth look at South Africa&rsquo;s history of racial segregation and the struggle for equality. Gold Reef City offers a glimpse into Johannesburg&rsquo;s gold mining past with its theme park and historical exhibits. The Maboneng Precinct is a trendy area known for its art galleries, eateries, and street performances, reflecting the city&rsquo;s creative spirit and diverse population.",
      imageUrl: "https://example.com/images/johannesburg.jpg"
    },
    { 
      id: 3,
      markerOffset: 15, 
      name: "Durban", 
      coordinates: [31.0216, -29.8587], 
      country_code: "ZAF",
      description: "Durban is famous for its golden beaches and vibrant cultural scene.",
      longDescription: "Durban, situated on the eastern coast of South Africa, is renowned for its warm subtropical climate and long stretches of golden sandy beaches. The city&rsquo;s beachfront promenade is a hub of activity, featuring a range of restaurants, shops, and entertainment options. Durban&rsquo;s cultural diversity is reflected in its culinary offerings, including delicious Indian cuisine and fresh seafood. uShaka Marine World is a major attraction, combining an aquarium, water park, and various marine-themed shows. Additionally, Durban is home to the Durban Botanical Gardens, a peaceful retreat showcasing a variety of indigenous and exotic plant species. The city&rsquo;s rich cultural heritage is celebrated through festivals and events that highlight its vibrant mix of communities.",
      imageUrl: "https://example.com/images/durban.jpg"
    },
    { 
      id: 4,
      markerOffset: 15, 
      name: "Pretoria", 
      coordinates: [28.2293, -25.7461], 
      country_code: "ZAF",
      description: "Pretoria is known for its historical landmarks and as the administrative capital of South Africa.",
      longDescription: "Pretoria, often referred to as the Jacaranda City due to its abundance of blooming jacaranda trees, serves as South Africa&rsquo;s administrative capital. The city&rsquo;s historic significance is highlighted by landmarks such as the Union Buildings, which house the offices of the President of South Africa, and the Voortrekker Monument, commemorating the pioneers who migrated from the Cape Colony. Pretoria is also home to several cultural and educational institutions, including the University of Pretoria and the Pretoria National Botanical Garden. The city&rsquo;s well-preserved architecture and numerous parks contribute to its charm, making it a city rich in history and natural beauty.",
      imageUrl: "https://example.com/images/pretoria.jpg"
    },
    { 
      id: 5,
      markerOffset: -30, 
      name: "Maputo", 
      coordinates: [32.5833, -25.9667], 
      country_code: "MOZ",
      description: "Maputo, the capital of Mozambique, is known for its vibrant culture and seaside beauty.",
      longDescription: "Maputo, Mozambique&rsquo;s capital city, is a vibrant and dynamic metropolis located on the Indian Ocean coast. Known for its eclectic mix of Portuguese colonial architecture and modern buildings, Maputo offers a unique cultural experience. The city&rsquo;s bustling Central Market is a hub of activity where visitors can find local produce, crafts, and souvenirs. The Maputo Fortress, a historic landmark built in the 18th century, provides insights into the city&rsquo;s colonial past. Maputo&rsquo;s coastal location means that fresh seafood is a staple in its culinary scene, with numerous restaurants offering a variety of delicious dishes. The city&rsquo;s lively arts and music scene further enhances its reputation as a cultural hotspot in Mozambique.",
      imageUrl: "https://example.com/images/maputo.jpg"
    },
    { 
      id: 6,
      markerOffset: 15, 
      name: "Beira", 
      coordinates: [34.8393, -19.8415], 
      country_code: "MOZ",
      description: "Beira is a major port city in Mozambique with a rich colonial history.",
      longDescription: "Beira, located on Mozambique&rsquo;s central coast, is a significant port city with a rich historical background. The city&rsquo;s colonial heritage is evident in its architecture, including the Beira Railway Station, which is an important landmark reflecting the city&rsquo;s role in the country&rsquo;s transportation network. Beira is also a gateway to the beautiful beaches and natural reserves along the coast. The city&rsquo;s port facilities make it a crucial economic center, and its vibrant market scene provides a glimpse into local life. Beira&rsquo;s blend of historical and modern elements creates a unique atmosphere that attracts visitors and supports regional trade.",
      imageUrl: "https://example.com/images/beira.jpg"
    },
    { 
      id: 7,
      markerOffset: 15, 
      name: "Nampula", 
      coordinates: [39.2633, -15.1163], 
      country_code: "MOZ",
      description: "Nampula is a city in northern Mozambique known for its cultural heritage.",
      longDescription: "Nampula, located in northern Mozambique, is an important regional center with a rich cultural heritage. The city is a focal point for trade and commerce in the region, and it boasts a variety of local markets where visitors can experience Mozambican culture and cuisine. Nampula is surrounded by scenic landscapes and traditional villages, offering opportunities for cultural exploration and eco-tourism. Historic sites and landmarks in the city provide insights into the region&rsquo;s history and development. The growing tourism industry in Nampula reflects its increasing popularity as a destination for those seeking to explore Mozambique&rsquo;s northern region.",
      imageUrl: "https://example.com/images/nampula.jpg"
    },
    { 
      id: 8,
      markerOffset: 15, 
      name: "Quelimane", 
      coordinates: [36.8880, -17.8784], 
      country_code: "MOZ",
      description: "Quelimane is known for its picturesque coastal views and rich cultural traditions.",
      longDescription: "Quelimane, situated on Mozambique&rsquo;s central coast, is a charming city known for its stunning coastal scenery and vibrant cultural traditions. The city offers picturesque views of the Indian Ocean and has several beautiful beaches that are popular with both locals and visitors. Quelimane&rsquo;s markets and historical buildings provide a glimpse into the city&rsquo;s cultural heritage, and its seafood cuisine is renowned for its freshness and flavor. The city&rsquo;s cultural events and festivals showcase the rich traditions of the local community, making it a notable destination for those interested in exploring Mozambique&rsquo;s coastal regions and cultural diversity.",
      imageUrl: "https://example.com/images/quelimane.jpg"
    }
  ];
  
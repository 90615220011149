// components/Protected.js
import { Auth } from 'aws-amplify';
import { SyncLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { FormEvent, useEffect, useState } from 'react';
import { createTheme, } from '@mui/material/styles';
import { Avatar, Box, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { getItem } from '../../services/api.svs';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/material/styles";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Currency {
  code: string;
  name: string;
  symbol: string;
}

interface User {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
}

const currencies: Currency[] = [
  { code: 'AOA', name: 'Angolan Kwanza', symbol: 'Kz' }, // Angola
  { code: 'BWP', name: 'Botswana Pula', symbol: 'P' }, // Botswana
  { code: 'CAD', name: 'Canadian Dollar', symbol: 'CA$' }, // Canada
  { code: 'EUR', name: 'Euro', symbol: '€' }, // Eurozone
  { code: 'ETB', name: 'Ethiopian Birr', symbol: 'Br' }, // Ethiopia
  { code: 'GHS', name: 'Ghanaian Cedi', symbol: 'GH₵' }, // Ghana
  { code: 'GBP', name: 'British Pound', symbol: '£' }, // United Kingdom
  { code: 'JPY', name: 'Japanese Yen', symbol: '¥' }, // Japan
  { code: 'KES', name: 'Kenyan Shilling', symbol: 'KSh' }, // Kenya
  { code: 'LSL', name: 'Lesotho Loti', symbol: 'L' }, // Lesotho
  { code: 'LYD', name: 'Libyan Dinar', symbol: 'LD' }, // Libya
  { code: 'MZN', name: 'Mozambican Metical', symbol: 'MT' }, // Mozambique
  { code: 'NAD', name: 'Namibian Dollar', symbol: 'N$' }, // Namibia
  { code: 'NGN', name: 'Nigerian Naira', symbol: '₦' }, // Nigeria
  { code: 'SDG', name: 'Sudanese Pound', symbol: 'SDG' }, // Sudan
  { code: 'SZL', name: 'Swazi Lilangeni', symbol: 'L' }, // Eswatini
  { code: 'TZS', name: 'Tanzanian Shilling', symbol: 'TSh' }, // Tanzania
  { code: 'UGX', name: 'Ugandan Shilling', symbol: 'USh' }, // Uganda
  { code: 'USD', name: 'US Dollar', symbol: '$' }, // United States
  { code: 'XAF', name: 'Central African CFA Franc', symbol: 'FCFA' }, // Cameroon
  { code: 'XOF', name: 'West African CFA Franc', symbol: 'CFA' }, // Senegal
  { code: 'ZAR', name: 'South African Rand', symbol: 'R' }, // South Africa
  { code: 'ZWL', name: 'Zimbabwean Dollar', symbol: 'Z$' }, // Zimbabwe
];


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const useStyles = makeStyles({
  textField: {
    '& .MuiInputLabel-root': {
      color: '#000', // Default label color
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#86c350', // Color when focused
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: '#f44336', // Color when error state
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#86c350',
        borderWidth: '2px'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#86c350',
        borderWidth: '2x'
      },
      '&.Mui-focused:hover fieldset': {
        borderColor: '#86c350',
        borderWidth: '2px'
      },
    },
  },
});

const ProfileHeadings = styled('h4')({
  color: "#000",
  textAlign: "center",
  fontFamily: "Righteous",
  fontSize: "2rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "4vh auto"
})

const ProfileText = styled('h6')({
  color: "#000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "1.3rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "left",
  marginBottom: "0.5vh",
  '@media only screen and (max-width: 600px)': {
    width: "70%",
    marginBottom: "4vh"
  }
})

const SaveButton = styled(Button)({
  backgroundColor: "#7ACA33",
  color: "#000",
  fontFamily: "Nova Round",
  fontSize: "1.5rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  flexShrink: 0,
  borderRadius: "10px",
  padding: 10,
  margin: "auto",
  textAlign: "center",
  '&:hover': {
    backgroundColor: '#4EA908',
  }
})


export function BusinessHome() {
  const { route } = useAuthenticator((context) => [context.route]);
  const classes = useStyles();

  const [formState, setFormState] = useState({
    business_name: '',
    description: '',
    phone: '',
    email: '',
    crn: '',
    industry: '',
    website: '',
    currency: 'ZAR',
    street_name: '',
    city_name: '',
    province_name: '',
    zip_code: '',
    linked_users: {}

  });

  // Regular expression for validating phone numbers (supports various formats)
  const phoneRegex = /^(?:(?:\+|00)([1-9]\d{0,2}))?([-\s./0-9]*\d[-\s./0-9]*)$/;

  // Regular expression for validating website URLs (supports HTTP and HTTPS)
  const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
  // Email regref
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Event handler for form input changes

  const [errors, setErrors] = useState({
    website: '',
    phone: '',
    email: '',
  });


  const handleChange = (event: any) => {
    const { name, value } = event.target;
    let errorMessage = '';

    // Perform validation based on the field name
    if (name === 'website') {
      console.log("Checking")
      if (!value.match(websiteRegex)) {
        errorMessage = 'Invalid website URL';
      }
    } else if (name === 'phone') {
      if (!value.match(phoneRegex)) {
        errorMessage = 'Invalid phone number';
      }
    } else if (name === 'email') {
      if (!value.match(emailRegex)) {
        errorMessage = 'Invalid email';
      }
    }

    // Update the form state and errors
    setFormState({ ...formState, [name]: value });
    setErrors({ ...errors, [name]: errorMessage });
  };

  const [isLoaded, setIsLoaded] = useState(true)
  const [notificationID, setNotificationID] = useState("")

  const [linkedUsers, setLinkedUsers] = useState<any[]>([]);
  const [newUser, setNewUser] = useState<User>({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
  });

  const [userError, setUserErrors] = useState({
    phone: '',
    email: '',
  });

  const [isValidAdd, setIsValidAdd] = useState(false)

  const validateUser = () => {
    let isValid = true;
    console.log("Validating")

    if (!newUser.email.match(emailRegex)) {
      console.log("Not valid email")
      isValid = false;
    }

    if (!newUser.phone_number.match(phoneRegex)) {
      console.log("Not valid phone")
      isValid = false;
    }
    console.log(isValid)
    setIsValidAdd(isValid)

  };

  const handleAddUser = (event: any) => {
    event.preventDefault();
    if (isValidAdd) {
      setLinkedUsers([...linkedUsers, { ...newUser }]);
      setNewUser({ first_name: '', last_name: '', phone_number: '', email: '' });
    }
  };

  const handleDeleteUser = (index: number) => {
    const updatedUsers = linkedUsers.filter((_, userIndex) => userIndex !== index);
    setLinkedUsers(updatedUsers);
  };

  const handleEditUser = (index: number, updatedUser: User) => {
    setLinkedUsers(linkedUsers.map((user, userIndex) => {
      return userIndex === index ? updatedUser : user;
    }));
  };

  const handleUserChange = (event: any) => {
    const { name, value } = event.target;
    let errorMessage = '';
    if (name === 'phone') {
      if (!value.match(phoneRegex)) {
        errorMessage = 'Invalid phone number';
      }
    } else if (name === 'email') {
      if (!value.match(emailRegex)) {
        errorMessage = 'Invalid email';
      }
    }
    setUserErrors({ ...errors, [name]: errorMessage });
    setNewUser({ ...newUser, [name]: value });
    const isValid = validateUser();
  };

  const savebusiness = (event: any) => {
    formState.linked_users = linkedUsers
    console.log(formState)
  };



  return (
    <>
      {isLoaded ? (
        <>
          <Grid container mt={4} sx={{ gap: 7, backgroundColor: "#f6f6f8" }}>
            <Grid sx={{ width: "80%", margin: "12vh auto 0vh auto" }}>
              <Paper elevation={8} style={{ padding: '20px', margin: 'auto', borderRadius: "1vh" }}>
                <ProfileHeadings>Company Info</ProfileHeadings>


                <Box
                  component="form"
                  sx={{
                    width: "80%",
                    margin: "4vh auto",
                  }}
                  noValidate
                  autoComplete="off"
                >
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="business_name"
                        label="Business Name"
                        type="text"
                        name="business_name"
                        value={formState.business_name}
                        onChange={handleChange}
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="phone"
                        label="Contact Number"
                        type="text"
                        name="phone"
                        value={formState.phone}
                        onChange={handleChange}
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                        error={errors.phone !== ''}
                        helperText={errors.phone}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="email"
                        label="Email"
                        type="email"
                        name="email"
                        value={formState.email}
                        onChange={handleChange}
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                        error={errors.email !== ''}
                        helperText={errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="crn"
                        label="CRN"
                        type="text"
                        name="crn"
                        value={formState.crn}
                        onChange={handleChange}
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="website"
                        label="What is your website?"
                        type="text"
                        name="website"
                        value={formState.website}
                        onChange={handleChange}
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                        error={errors.website !== ''}
                        helperText={errors.website}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        sx={{ width: "100%", textAlign: "center", justifyContent: "center", display: "flex" }}
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{
                            '&.Mui-focused': {
                              color: '#86c350', // Change color when focused
                            }
                          }}
                        >
                          Industry
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="industry"
                          value={formState.industry}
                          label="Industry"
                          name="industry"
                          onChange={handleChange}
                          sx={{
                            '&:hover': {
                              border: "2px solid #86c350 !important"
                            },
                            '&:focus': {
                              border: "2px solid #86c350 !important"
                            },
                            '&.Mui-focused': {
                              border: "2px solid #86c350 !important"
                            },
                            '&.Mui-focused:hover': {
                              border: "2px solid #86c350 !important"
                            },
                            '&.Mui-active': {
                              border: "2px solid #86c350 !important"
                            },
                            height: '100%' // Adjust height to match text fields
                          }}
                        >
                          <MenuItem value={'construction'}>Construction</MenuItem>
                          <MenuItem value={'electrical'}>Electrical</MenuItem>
                          <MenuItem value={'manufacturing'}>Manufacturing</MenuItem>
                          <MenuItem value={'technology'}>Technology</MenuItem>
                          <MenuItem value={'finance'}>Finance</MenuItem>
                          <MenuItem value={'healthcare'}>Healthcare</MenuItem>
                          <MenuItem value={'retail'}>Retail</MenuItem>
                          <MenuItem value={'transportation'}>Transportation</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="description"
                        label="What do you do?"
                        type="text"
                        name="description"
                        value={formState.description}
                        onChange={handleChange}
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        sx={{ width: "100%", textAlign: "center", justifyContent: "center", display: "flex" }}
                      >
                        <InputLabel
                          id="currency-select-label"
                          sx={{
                            '&.Mui-focused': {
                              color: '#86c350', // Change color when focused
                            }
                          }}
                        >
                          What currency do you work with?
                        </InputLabel>
                        <Select
                          labelId="currency-select-label"
                          id="currency"
                          name="currency"
                          value={formState.currency}
                          onChange={handleChange}
                          sx={{
                            '&:hover': {
                              border: "2px solid #86c350 !important"
                            },
                            '&:focus': {
                              border: "2px solid #86c350 !important"
                            },
                            '&.Mui-focused': {
                              border: "2px solid #86c350 !important"
                            },
                            '&.Mui-focused:hover': {
                              border: "2px solid #86c350 !important"
                            },
                            '&.Mui-active': {
                              border: "2px solid #86c350 !important"
                            },
                            height: '100%' // Adjust height to match text fields
                          }}
                        >
                          {currencies.map(currency => (
                            <MenuItem key={currency.code} value={currency.code}>
                              {currency.name} ({currency.symbol})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>


                <Box sx={{ margin: "auto", textAlign: "center" }}>

                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      backgroundColor: "#7ACA33",
                      color: "#000",
                      fontFamily: "Nova Round",
                      fontSize: "1.2rem",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      flexShrink: 0,
                      borderRadius: "10px",
                      padding: 2,
                      margin: "0vh auto 4vh auto",
                      textAlign: "center",
                      '&: hover': {
                        backgroundColor: "#4EA908"
                      }
                    }}
                  >
                    Upload file
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </Box>

              </Paper>
            </Grid>

            <Grid sx={{ width: "80%", margin: "0vh auto" }}>
              <Paper elevation={8} style={{ padding: '20px', margin: 'auto', borderRadius: "1vh" }}>
                <ProfileHeadings>Address</ProfileHeadings>
                <Box
                  component="form"
                  sx={{
                    width: "80%",
                    margin: "4vh auto",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="street_name"
                        label="Street Name"
                        name="street_name" // Add name attribute
                        type="text"
                        className={classes.textField}
                        value={formState.street_name}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="city_name"
                        label="City Name"
                        name="city_name" // Add name attribute
                        type="text"
                        className={classes.textField}
                        value={formState.city_name}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="provinceName"
                        label="Province"
                        name="province_name" // Add name attribute
                        type="text"
                        className={classes.textField}
                        value={formState.province_name}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="zip_code"
                        label="Zip Code"
                        name="zip_code" // Add name attribute
                        type="text"
                        className={classes.textField}
                        value={formState.zip_code}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                </Box>
              </Paper>
            </Grid>

            <Grid sx={{ width: '80%', margin: '0vh auto' }}>
              <Paper elevation={8} style={{ padding: '20px', margin: 'auto', borderRadius: '1vh' }}>
                <ProfileHeadings>Invite other users</ProfileHeadings>

                <Box
                  component="form"
                  onSubmit={handleAddUser}
                  sx={{
                    width: '80%',
                    margin: '4vh auto',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        type="text"
                        onChange={handleUserChange}
                        value={newUser.first_name}
                        fullWidth
                        className={classes.textField}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="last_name"
                        label="Last Name"
                        name="last_name"
                        type="text"
                        value={newUser.last_name}
                        onChange={handleUserChange}
                        fullWidth
                        className={classes.textField}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="phone_number"
                        label="Phone"
                        name="phone_number"
                        type="text"
                        value={newUser.phone_number}
                        onChange={handleUserChange}
                        fullWidth
                        className={classes.textField}
                        error={userError.phone !== ''}
                        helperText={userError.phone}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="email"
                        label="Email"
                        name="email"
                        type="text"
                        className={classes.textField}
                        value={newUser.email}
                        onChange={handleUserChange}
                        fullWidth
                        error={userError.email !== ''}
                        helperText={userError.email}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ margin: "auto", textAlign: "center" }}>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleAddUser}
                      sx={{
                        backgroundColor: '#7ACA33',
                        color: '#000',
                        fontFamily: 'Nova Round',
                        fontSize: '1.2rem',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        borderRadius: '10px',
                        padding: 2,
                        margin: '4vh auto',
                        '&:hover': {
                          backgroundColor: '#4EA908'
                        }
                      }}
                      disabled={!isValidAdd}
                    >
                      Invite
                    </Button>
                  </Box>
                  <FormControl fullWidth>
                    {linkedUsers.map((user, index) => (
                      <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {`${user.first_name} ${user.last_name}`}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            component="form"
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleEditUser(index, user);
                            }}
                            sx={{
                              width: '100%',
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id={`first_name-${index}`}
                                  label="First Name"
                                  name="first_name"
                                  value={user.first_name}
                                  onChange={(e) => {
                                    const updatedUser = { ...user, first_name: e.target.value };
                                    handleEditUser(index, updatedUser);
                                  }}
                                  type="text"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id={`last_name-${index}`}
                                  label="Last Name"
                                  name="last_name"
                                  value={user.last_name}
                                  onChange={(e) => {
                                    const updatedUser = { ...user, last_name: e.target.value };
                                    handleEditUser(index, updatedUser);
                                  }}
                                  type="text"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id={`phone_number-${index}`}
                                  label="Phone Number"
                                  name="phone_number"
                                  value={user.phone_number}
                                  onChange={(e) => {
                                    const updatedUser = { ...user, phone_number: e.target.value };
                                    handleEditUser(index, updatedUser);
                                  }}
                                  type="text"
                                  fullWidth

                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id={`email-${index}`}
                                  label="Email"
                                  name="email"
                                  value={user.email}
                                  onChange={(e) => {
                                    const updatedUser = { ...user, email: e.target.value };
                                    handleEditUser(index, updatedUser);
                                  }}
                                  type="text"
                                  fullWidth

                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </AccordionDetails>
                        <AccordionActions>
                          <Button
                            onClick={() => handleDeleteUser(index)}
                            sx={{
                              color: 'red',
                            }}
                          >
                            Delete
                          </Button>
                        </AccordionActions>
                      </Accordion>
                    ))}

                  </FormControl>
                </Box>


              </Paper>
            </Grid>

            <Grid container sx={{ margin: "8vh auto", textAlign: "center" }}>
              <SaveButton onClick={savebusiness}>
                Save
              </SaveButton>
            </Grid>

          </Grid>

        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><SyncLoader color="#36d7b7" /></div>

        </div>
      )}
    </>


  );
}


// components/Home.js
//CSS
import "./classrooms.css";


//IMAGES
import classRoomBackground from "../../assets/woodstockie/classroom/IMG_8857.webp";
import trainingRoomImage1 from '../../assets/images/trainingRoomImage1.png';
import trainingRoomImage2 from '../../assets/images/trainingRoomImage2.png';
import trainingRoomImage3 from '../../assets/images/trainingRoomImage3.png';
import trainingRoomImage4 from '../../assets/images/trainingRoomImage4.png';


//MUI COMPONENTS
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import { css } from '@emotion/react';
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styled from '@emotion/styled';
import { Typography } from "@mui/material";


//OTHER COMPONENTS
import * as React from "react";
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import Maps from "../../components/maps/Maps";
import { SyncLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import SwipeableTextMobileStepper from '../home/carousel';
import { useEffect, useState } from "react";


const StyledPaper = styled(Paper)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#000000B2',
  textAlign: 'left',
  boxSizing: 'border-box',
  color: '#ffffff',
  wordWrap: 'break-word',
  fontWeight: 400
});

const StyledContainer = styled(Paper)({
  position: 'relative',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url('${classRoomBackground}')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'grayscale(100%)',
  padding: 0
});


const BannerText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'Righteous',
  fontSize: '100px',
  fontWeight: 400,
  margin: '0 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '2.5rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '3rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const BannerSubText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'M PLUS Rounded 1c',
  fontSize: '40px',
  fontWeight: 400,
  margin: '1vh 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.25rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '2rem',
    margin: 'auto',
    textAlign: 'center',
  },
});


const LandingSectionStyles = styled.div`
  background-image: url(${classRoomBackground});
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat; 
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 1vh auto;
  filter: grayscale(100%);
`;

const PageHeading = styled('h1')({
  color: "#4EA908",
  fontFamily: "Righteous",
  fontSize: "40px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "49.67px",
  margin: "4vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.5rem',
    margin: 'auto',
    textAlign: 'center',
    padding: "0 2vw"
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '1.8rem',
    margin: 'auto',
    textAlign: 'center',
    padding: "0 2vw"
  },
})

const InfoHeading = styled('h6')({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 800,
  margin: "4vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.375rem',
    margin: 'auto',
    textAlign: 'center'
  },
})

const InfoText = styled('p')({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 400,
  margin: "4vh auto",
  lineHeight: "35px",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.2rem',
  },
})

const QuoteButton = styled(Button)({
  backgroundColor: "#4EA908",
  padding: '16px 32px',
  fontSize: '3.125rem',
  borderRadius: "80px",
  color: "#000000",
  fontFamily: "Righteous",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "62px",
  textAlign: "center",
  margin: "4vh auto",
  textTransform: "capitalize",
  '&:hover': {
    backgroundColor: '#4EA908',
},

'@media only screen and (max-width: 600px)': {
  fontSize: '2rem',
  margin: '2vh auto',
  padding: '1vh 4vw'
},

'@media only screen and (min-width: 601px) and (max-width: 768px)': {
  fontSize: '2rem',
  margin: '2vh auto',
  padding: '1vh 4vw'
},
})

const EndText = styled('h1')({
  color: "#000000",
  fontFamily: "Righteous",
  fontSize: "36px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "44px",
  margin: "4vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: "1.2rem",
    margin: "auto",
    textAlign: "center",
    padding: "2vh 2vw"
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '1.4rem',
    margin: '2vh auto',
    textAlign: 'center',
    padding: "0 2vw"
  },
})


const trainingRoomImages = [
  { imgPath: trainingRoomImage1 },
  { imgPath: trainingRoomImage2 },
  { imgPath: trainingRoomImage3 },
  { imgPath: trainingRoomImage4 },
];


export function Classrooms() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
    
      <Grid container>
      <StyledContainer>
          <StyledPaper>
            <BannerText>TRAINING ROOMS</BannerText>
            <BannerSubText>Create engaging and functional learning spaces</BannerSubText>
          </StyledPaper>
        </StyledContainer>

        <Grid container>
          <PageHeading>
            Providing versatile and inventive solutions to meet your educational needs through:
          </PageHeading>
        </Grid>

        <Grid container spacing={3} sx={{ display: "grid", gridTemplateColumns: {xs: "1fr", md: "1fr 1fr"}, justifyItems: "center", margin: "4vh auto"}}>

          {/* First Column */}
          <Grid item xs={11} md={7}>
              <InfoHeading>Dynamic classrooms</InfoHeading>
              <InfoText>
                Adapt the workspace as your organization grows, ensure maximum efficiencyTransform standard containers into interactive classrooms that foster creativity and collaboration
              </InfoText>
          </Grid>

          {/* Second Column */}
          <Grid item xs={11} md={7}>
              <InfoHeading>Cost-effective design</InfoHeading>
              <InfoText>
                Save costs compared to traditional construction while delivering functional and inspiring educational spaces
              </InfoText>
          </Grid>

          
          {/* Row 2 */}
          <Grid item xs={11} md={7}>
              <InfoHeading>Quick deployment</InfoHeading>
              <InfoText>
                Expedite the setup of temporary or permanent learning environments without compromising quality
              </InfoText>
          </Grid>

          <Grid item xs={11} md={7}>
              <InfoHeading>Sustainable solutions</InfoHeading>
              <InfoText>
                Embrace sustainable practices with eco-friendly materials and energy-efficient designs
              </InfoText>
          </Grid>

          {/* Row 3 */}
          <Grid item xs={11} md={7}>
              <InfoHeading>Tailored solutions</InfoHeading>
              <InfoText>
                We work closely with educational institutions to ensure our container solutions align with their vision
              </InfoText>
          </Grid>

          <Grid item xs={11} md={7} sx={{ margin: "intial 2vw"}}>
              <InfoHeading>Adaptability (modular design)</InfoHeading>              
              <InfoText>
                Adapt the space as educational needs evolve, whether for classrooms, libraries, or specialized labs
              </InfoText>
          </Grid>
        </Grid>

        <Grid container>
          <SwipeableTextMobileStepper images={trainingRoomImages} />;
        </Grid>

        <Grid container>
          <EndText>
            We are your partner in transforming education spaces
          </EndText>
        </Grid>

        <Grid container>
          <QuoteButton>Get a Quote</QuoteButton>
        </Grid>
      </Grid>

    </>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

interface Image {
  label?: string;
  imgPath: string;
}

interface SwipeableTextMobileStepperProps {
  images: Image[];
}

function SwipeableTextMobileStepper({ images }: SwipeableTextMobileStepperProps) {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Detect tablets

  const itemsPerPage = isPhone ? 1 : isTablet ? 0.95 : 2;
  const totalItems = images.length;

  const maxSteps = totalItems > itemsPerPage ? Math.ceil(totalItems - itemsPerPage + 1) : 1;

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + maxSteps) % maxSteps);
  };

  const translateValue = -(activeIndex * 100) / itemsPerPage;

  return (
    <Box sx={{ maxWidth: { xs: 280, md: 800 }, margin: '10vh auto', position: 'relative' }}>
      <IconButton
        onClick={handleBack}
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
          zIndex: 1,
          color: '#a86d1e',
        }}
      >
        <KeyboardArrowLeft sx={{ backgroundColor: "#86C350", borderRadius: "50%", color: "white", fontSize: "2rem" }} />
      </IconButton>

      <Grid container spacing={2} sx={{ overflow: 'hidden', position: 'relative' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            transition: 'transform 0.5s ease-in-out',
            transform: `translateX(${translateValue}%)`,
            width: `${totalItems * (100 / itemsPerPage)}%`
          }}
        >
          {images.map((image) => (
            <Card
              key={image.label}
              sx={{
                backgroundColor: '#d9d9d9',
                minWidth: isPhone ? 280 : isTablet ? 320 : 400,
                flexShrink: 0,
                marginRight: '20px',
              }}
            >
              <CardMedia component="img" height="200" image={image.imgPath} alt={image.label} />
              <CardContent sx={{ backgroundColor: "#E9E9E9" }}>
                <Typography variant="h6">{image.label}</Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>

      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: { xs: 'translateY(-50%)', lg: 'translateY(-50%)' },
          zIndex: 1,
          color: '#a86d1e',
        }}
      >
        <KeyboardArrowRight sx={{ backgroundColor: "#86C350", borderRadius: "50%", color: "white", fontSize: "2rem" }} />
      </IconButton>
    </Box>
  );
}

export default SwipeableTextMobileStepper;

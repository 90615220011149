// components/Home.js
//CSS
import "./aluminium.css";


//IMAGES
import aluminiumBackground from "../../assets/woodstockie/aluminium/Aluminium8.webp";
import aluminiumImage1 from '../../assets/images/aluminiumImage1.png';
import aluminiumImage2 from '../../assets/images/aluminiumImage2.png';
import aluminiumImage3 from '../../assets/images/aluminiumImage3.png';
import aluminiumImage4 from '../../assets/images/aluminiumImage4.png';
import aluminiumImage5 from '../../assets/images/aluminiumImage5.png';
import aluminiumImage6 from '../../assets/images/aluminiumImage6.png';
import aluminiumImage7 from '../../assets/images/aluminiumImage7.png';
import aluminiumImage8 from '../../assets/images/aluminiumImage8.png';
import aluminiumImage9 from '../../assets/images/aluminiumImage9.png';


//MUI COMPONENTS
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import { css } from '@emotion/react';
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styled from '@emotion/styled';
import { Typography } from "@mui/material";


//OTHER COMPONENTS
import * as React from "react";
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import Maps from "../../components/maps/Maps";
import { SyncLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import SwipeableTextMobileStepper from '../home/carousel';
import { useEffect, useState } from "react";


const StyledPaper = styled(Paper)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#000000B2',
  textAlign: 'left',
  boxSizing: 'border-box',
  color: '#ffffff',
  wordWrap: 'break-word',
  fontWeight: 400
});

const StyledContainer = styled(Paper)({
  position: 'relative',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url('${aluminiumBackground}')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'grayscale(100%)',
  padding: 0
});


const BannerText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'Righteous',
  fontSize: '100px',
  fontWeight: 400,
  margin: '0 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '2.5rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '3rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const BannerSubText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'M PLUS Rounded 1c',
  fontSize: '40px',
  fontWeight: 400,
  margin: '1vh 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.25rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '2rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const LandingSectionStyles = styled.div`
  background-image: url(${aluminiumBackground});
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat; 
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 15vh auto;
  filter: grayscale(100%);
`;

const PageHeading = styled('h1')({
  color: "#4EA908",
  fontFamily: "Righteous",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "49.67px",
  margin: "4vh auto",
  '@media(max-width: 1000px)': {
    margin: "2vh 4vw",
    fontSize: '20px',
    wordBreak: 'break-all'
  },
})

const InfoHeading = styled('h6')({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "28px",
  fontStyle: "normal",
  fontWeight: 800,
  margin: "4vh auto"
})

const InfoText = styled('p')({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 400,
  margin: "4vh auto",
  lineHeight: "35px",
  textAlign: "left"
})

const QuoteButton = styled(Button)({
  backgroundColor: "#4EA908",
  padding: '12px 32px',
  fontSize: '34px',
  borderRadius: "80px",
  color: "#000000",
  fontFamily: "Righteous",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "62px",
  textAlign: "center",
  margin: "4vh auto",
  textTransform: "capitalize",
  '&:hover': {
    backgroundColor: '#4EA908',
    color: "#fff"
}
})

const EndText = styled('h1')({
  color: "#000000",
  fontFamily: "Righteous",
  fontSize: "36px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "44px",
  margin: "4vh auto",
  '@media(max-width: 1000px)': {
    margin: "2vh 4vw",
    fontSize: '20px',
  },
})

const aluminiumImages = [
  {  imgPath: aluminiumImage1 },
  {  imgPath: aluminiumImage2 },
  {  imgPath: aluminiumImage3 },
  {  imgPath: aluminiumImage4 },
  {  imgPath: aluminiumImage5 },
  {  imgPath: aluminiumImage6 },
  {  imgPath: aluminiumImage7 },
  {  imgPath: aluminiumImage8 },
  {  imgPath: aluminiumImage9 },
];

export function Aluminium() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
    
      <Grid container>
        <StyledContainer>
          <StyledPaper>
            <BannerText>ALUMINIUM</BannerText>
            <BannerSubText>Step into a world of exceptional Aluminium craftmanship</BannerSubText>
          </StyledPaper>
        </StyledContainer>

        <Grid container>
          <PageHeading>
            Everything from exquisite Aluminium windows to inviting storefront
          </PageHeading>
        </Grid>

        <Grid container>
          <PageHeading>
            We offer a range of tailored solutions that harmonize aesthetics and functionality through:
          </PageHeading>
        </Grid>

        <Grid container spacing={3} sx={{ display: "grid", gridTemplateColumns: {xs: "1fr", sm: "1fr 1fr"}, justifyItems: "center", margin: "4vh auto"}}>

          {/* First Column */}
          <Grid item xs={10} md={8}>
              <InfoHeading>Affordability</InfoHeading>
              <InfoText>
                We believe in delivering premium Aluminium products without breaking the bank. Our competitive pricing ensures you get the best value for your commitment
              </InfoText>
          </Grid>

          {/* Second Column */}
          <Grid item xs={10} md={8}>
              <InfoHeading>Custom craftmanship</InfoHeading>
              <InfoText>
                Your vision is our blueprint. Our skilled artisans create custom-made Aluminium solutions that seamlessly integrate into your space. reflecting your unique style and needs.
              </InfoText>
          </Grid>

          
          {/* Row 2 */}
          <Grid item xs={10} md={8}>
              <InfoHeading>Quality beyond compromise</InfoHeading>
              <InfoText>
                Only the finest materials meet our standards. With a focus on durability and longevity, our Aluminium products are crafted to withstand the test of time
              </InfoText>
          </Grid>

          <Grid item xs={10} md={8}>
              <InfoHeading>Swift and Reliable</InfoHeading>
              <InfoText>
                Time is of the essence, and we honour that. Our fast service ensures that your Aluminium projects are execute efficiently, without compromising on quality
              </InfoText>
          </Grid>
        </Grid>

        <Grid container>
          <SwipeableTextMobileStepper images={aluminiumImages} />;
        </Grid>

        <Grid container>
          <EndText>
            Experience the difference exceptional Aluminium  can make in your space
          </EndText>
        </Grid>

        <Grid container>
          <QuoteButton>Get a Quote</QuoteButton>
        </Grid>

        <Grid container>
          <EndText>
            AND
          </EndText>
        </Grid>

        <Grid container>
          <EndText>
            Discover the art of balancing affordability, customization and quality with Woodstock Projects
          </EndText>
        </Grid>
      </Grid>

    </>
  );
}

import "../../layout/footer/Footer.css";
import "./Footer.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { TextField } from "@aws-amplify/ui-react";
import Button from "@mui/material/Button";
import { Container, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Icon } from '@material-ui/core';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';


export function Footer() {

  const FooterHeadings = styled('h4')({
    color: "#000",
    fontFamily: "Nova Round",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "3vh",
    marginBottom: "3vh"
  })

  const FooterText = styled('h6')({
    color: "#000",
    fontFamily: "Rounded Mplus 1c",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "1vh"
  })

  return (
    <>
    <Grid  sx={{ width: "100vw", backgroundColor: "#86C350", display: "grid", gridTemplateColumns: {xs: "1fr", md: "1fr 1fr"}, justifyContent: "space-between", justifySelf: "center", margin: "0vh", padding: "0vh"}}>
        <Grid item xs={12} sx={{ margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          <FooterHeadings>
            CONTACT
          </FooterHeadings>

          <FooterText sx={{ fontSize: {xs: "1.3rem", sm: "1.5rem"}}}>Plot 161, Hallgate, Nigel, 1490</FooterText>
          <FooterText sx={{textAlign: {xs: "center", sm: "left"}}}>082 414 2858</FooterText>
          <FooterText sx={{textAlign: {xs: "center", sm: "left"}}}>083 280 9079</FooterText>
          <FooterText sx={{textAlign: {xs: "center", sm: "left"}}} className="email"><a href="mailto:corne@woodstockie.com">corne@woodstockie.com</a></FooterText>
          <FooterText sx={{textAlign: {xs: "center", sm: "left"}}} className="email"><a href="mailto:sales1@woodstockie.com">sales1@woodstockie.com</a></FooterText>

          <Grid container sx={{display: "flex", justifyContent: "center"}}>

          <Grid item sx={{marginX: "1vh"}}>
            <Icon>
              <WhatsAppIcon sx={{ backgroundColor: "#444", color: "#86C350", borderRadius: "50%", padding: "0.2vh"}} />
            </Icon>
          </Grid>

          <Grid item sx={{marginX: "1vh"}}>
            <Icon>
              <LinkedInIcon sx={{color: "#444"}} />
            </Icon>
          </Grid>

          <Grid item sx={{marginX: "1vh"}}>
            <Icon>
              <TwitterIcon sx={{color: "#444"}} />
            </Icon>
          </Grid>

          <Grid item sx={{marginX: "1vh"}}>
            <Icon>
              <FacebookIcon sx={{color: "#444" }} />
            </Icon>
          </Grid>
          
        </Grid>
        </Grid>

        <Grid item xs={12} sx={{ margin: "0vh auto", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "0 4vw" }}>
          <FooterHeadings>
            TRADING HOURS
          </FooterHeadings>

          <FooterText>MONDAY - THURSDAY</FooterText>
          <FooterText sx={{marginBottom: "2vh"}}>7:00 - 17:00</FooterText>

          <FooterText>FRIDAY</FooterText>
          <FooterText sx={{marginBottom: "2vh"}}>7:00 - 15:00</FooterText>

          <FooterText>WEEKENDS</FooterText>
          <FooterText>closed</FooterText>
      </Grid>
    </Grid>
    </>
  );
}
import { SetStateAction, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import Card from "@mui/material/Card";
import { Box, Button, Container, Grid, Input, Snackbar, TextField, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/material/styles";

const useStyles = makeStyles({
  textField: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#86c350',
        borderWidth: '2px'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#86c350',
        borderWidth: '2px'
      },
      '&.Mui-focused:hover fieldset': {
        borderColor: '#86c350',
        borderWidth: '2px'
      },
    },
  },
});

const ForgotHeading = styled(Typography)({
  color: "#000",
  textAlign: "center",
  fontFamily: "Righteous",
  fontSize: "3rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "uppercase",
  marginTop: "2vh"
});

const ForgotText = styled(Typography)({
  color: "#000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "1.3rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "left",
  margin: "0.5vh auto 0vh auto"
});

export function Forgot() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [codeSent, setCodeSent] = useState(false);
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const handleUsername = (event: { currentTarget: { value: SetStateAction<string>; }; }) => {
    setUsername(event.currentTarget.value);
  };

  const handleVerificationCode = (event: { currentTarget: { value: SetStateAction<string>; }; }) => {
    setVerificationCode(event.currentTarget.value);
  };

  const handlePassword = (event: { currentTarget: { value: SetStateAction<string>; }; }) => {
    setPassword(event.currentTarget.value);
  };

  const handlePasswordConfirmation = (event: { currentTarget: { value: SetStateAction<string>; }; }) => {
    setPasswordConfirmation(event.currentTarget.value);
  };

  const verificationHandler = async () => {
    if (password === passwordConfirmation) {
      console.log("Setting ", username);
      await Auth.forgotPasswordSubmit(username, verificationCode, password)
        .then((data) => {
          console.log(data);
          navigate('/profile');
        })
        .catch((err) => {
          console.log(err);
          openAlertFunction("warning", "Issues resetting password", "We are having issues changing your password.");
        });
    } else {
      openAlertFunction("warning", "Passwords don't match", "Please make sure your passwords match.");
    }
  };

  const sendUserCode = async () => {
    await Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        setCodeSent(true);
      })
      .catch((err) => {
        openAlertFunction("warning", "Issue sending code", err.message);
      });
  };

  const [alertType, setAlertType] = useState("success");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const alertAction = () => setOpenAlert(false);

  const openAlertFunction = (type: SetStateAction<string>, title: SetStateAction<string>, message: SetStateAction<string>) => {
    setAlertType(type);
    setAlertTitle(title);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const renderAlert = (
    <Snackbar
      color={alertType}
      title={alertTitle}
      message={alertMessage}
      open={openAlert}
      onClose={alertAction}
      sx={{ background: 'white' }}
    />
  );

  return (
    <Container>
      <Grid container
        spacing={6}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Card sx={{ boxShadow: "0px 14px 18px rgba(0, 0, 0, 0.2)", width: "30vw", minWidth: "250px" }}>
            <Box>
              <ForgotHeading variant="h3">
                Reset Password
              </ForgotHeading>
            </Box>

            <Box pt={4} pb={4} px={0} sx={{ display: "flex", justifyContent: "center" }}>
              {!codeSent ? (
                <>
                  <Box component="form" role="form">
                    <Box mb={2}>
                      <TextField
                        type="email"
                        placeholder="Email"
                        value={username}
                        fullWidth
                        onChange={handleUsername}
                        variant="outlined"
                        className={classes.textField}
                        margin="normal"
                        required
                        id="email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                      />
                    </Box>

                    <Box mb={2}>
                      <Button fullWidth onClick={sendUserCode}
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          color: "#7BC24F",
                          "&: hover": {
                            backgroundColor: "#7BC24F",
                            color: "#fff"
                          }
                        }}
                      >
                        Reset
                      </Button>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box pt={4} pb={3} px={3}>
                    <Box component="form" role="form">
                      <Box mb={2}>
                        <Input type="text" placeholder="Verification Code" value={verificationCode} fullWidth onChange={handleVerificationCode} />
                      </Box>
                      <Box mb={2}>
                        <Input type="password" placeholder="Password" value={password} fullWidth onChange={handlePassword} />
                      </Box>
                      <Box mb={2}>
                        <Input type="password" placeholder="Confirm Password" value={passwordConfirmation} fullWidth onChange={handlePasswordConfirmation} />
                      </Box>
                      <Box my={4} sx={{ display: "flex", justifyContent: "center" }}>
                        {/* <Button color="warning" fullWidth >
                                                    Reset
                                                </Button> */}
                        <Button
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            color: "#7BC24F",
                            "&: hover": {
                              backgroundColor: "#7BC24F",
                              color: "#fff"
                            }
                          }}
                          onClick={verificationHandler}
                        >
                          Reset
                        </Button>
                      </Box>
                      <Box mt={3} mb={1} textAlign="center">
                        <ForgotText variant="button">
                          Already have an account?{" "}
                          <Typography
                            component={Link}
                            to="/login"
                            variant="button"
                            color="warning"
                            fontWeight="medium"
                          >
                            Sign In
                          </Typography>
                        </ForgotText>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {renderAlert}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

import React from 'react'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';


export interface MapsProps {
  width?: string;
  heigh?: string;
  lat: number;
  lng: number;
}

function Maps(props: MapsProps) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_TOKEN!
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map: any) {

    map.setZoom(14)

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const handleMarkerClick = () => {
    // Check if user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Mobile device - open maps app
      const mapUrl = `geo:${props.lat},${props.lng}`;
      window.location.href = mapUrl;
    } else {
      // Desktop device - open maps in new tab
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${props.lat},${props.lng}`;
      window.open(mapsUrl, '_blank');
    }
  };


  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: props.width,
        height: props.heigh
      }}
      center={{
        lat: props.lat,
        lng: props.lng
      }}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      <>
        <MarkerF
          position={{ lat: props.lat, lng: props.lng }}
          onClick={handleMarkerClick}
        />
      </>
    </GoogleMap>
  ) : <></>
}

export default React.memo(Maps)
import { Modal, useMediaQuery, useTheme, Box, IconButton, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography, Marker, Graticule} from "react-simple-maps";
import { useNavigate, useParams } from "react-router-dom";
import { markers } from "./project_data";
import CloseIcon from '@mui/icons-material/Close';

const projectionConfigs = {
  "ZAF": { rotate: [-30, 28, 0], scale: 300 }, // South Africa
  "MOZ": { rotate: [-31, 26, 0], scale: 300 }, // Mozambique
  "EGY": { rotate: [-16, -16, 0], scale: 300 }  // Egypt
  // Add more countries and their rotation values here
};

export function ProjectCountry() {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null); // State for the selected marker
  const [projectionConfig, setProjectionConfig] = useState({
    rotate: [-30, 28, 0], // Default rotation
    scale: 1400
  });
  const { country_code } = useParams(); // Get country_code from URL

  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page

    // Filter projects based on country_code from URL
    const filteredProjects = markers.filter(marker => marker.country_code === country_code);
    setProjectList(filteredProjects);

    // Set the projection config based on the country code
    const config = projectionConfigs[country_code] || projectionConfigs["ZAF"]; // Default to South Africa if not found
    setProjectionConfig(config);
  }, [country_code]);

  const geoUrl = "/world.json";

  const handleMouseEnter = (e, geo) => {
    e.preventDefault();
    if (geo.id === country_code) {
      handleOpen();
    }
  };

  const handleMarkerHover = (e, marker) => {
    e.preventDefault();
    setSelectedMarker(marker);
  };

  const handleMarkerRemove = (e) => {
    e.preventDefault();
    setSelectedMarker(null);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleProjectClick = (project) => {
    const matchingMarker = projectList.find(marker => marker.id === project.id);
    if (matchingMarker) {
      setSelectedMarker(matchingMarker);
    }
  };

  const handleCloseDetails = () => {
    setSelectedMarker(null);
  };

  const handleViewMore = () => {
    if (selectedMarker) {
      navigate(`/projects/${country_code}/${selectedMarker.id}`);
    }
  };

  const handleBackToWorldView = () => {
    navigate('/projects'); // Navigate to the world view route, adjust this to match your route
  };

  return (
    <>
      <div className="map-container" style={{ position: 'relative' }}>
        {/* World View Button */}
        <Button
          variant="contained"
          onClick={handleBackToWorldView}
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            zIndex: 1,
            backgroundColor: "#86C350",
            color: theme.palette.primary.contrastText,
          }}
        >
          World View
        </Button>

        <ComposableMap
          projection="geoAzimuthalEqualArea"
          projectionConfig={{
            rotate: projectionConfig.rotate,
            scale: projectionConfig.scale
          }}
          style={{ width: "100%", height: "70vh" }}
        >
          <Graticule stroke="#86C350" />
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(e) => handleMouseEnter(e, geo)}

                  style={{
                    default: {
                      fill: geo.id === country_code ? "#86C350" : "#666666", // Conditional fill color
                    },
                    hover: {
                      fill: geo.id === country_code ? "#86C350" : "#666666",
                    },
                    pressed: {
                      fill: geo.id === country_code ? "#86C350" : "#666666",
                    },
                  }}
                />
              ))
            }
          </Geographies>
          {projectList.map(marker => (
            <Marker
              key={marker.id}
              coordinates={marker.coordinates}
              onMouseEnter={(e) => handleMarkerHover(e, marker)}
              onClick={() => handleMarkerClick(marker)}
            >
              <g
                fill="none"
                stroke={selectedMarker?.id === marker.id ? "#fdf203" : "#0b0a0a"} // Change color based on selected marker
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(-12, -24)"
              >
                <circle cx="12" cy="10" r="3" />
                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
              </g>
            </Marker>
          ))}
        </ComposableMap>

        <Box
          sx={{
            padding: 2,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            width: isMediumScreen ? '100%' : '30vw',
            maxWidth: isMediumScreen ? '100%' : '500px',
            height: isMediumScreen ? '100%' : 'auto',
            position: 'relative', // To position content inside
            margin: isMediumScreen ? 'auto' : '0',
          }}
        >

          {selectedMarker ? (
            <>
              <h2>Project</h2>
              <IconButton
                onClick={handleCloseDetails}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  zIndex: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
              <h3>{selectedMarker.name}</h3>
              <img
                src={selectedMarker.imageUrl}
                alt={selectedMarker.name}
                style={{ width: '100%', maxHeight: '300px', objectFit: 'cover', marginBottom: '10px' }}
              />
              <p>{selectedMarker.description}</p>
              <Button sx={{ backgroundColor: "#86C350" }} onClick={handleViewMore}>View more</Button>
            </>
          ) : (
            <>
              <h2>Projects</h2>
              {projectList.map((project) => (
                <p
                  key={project.id}
                  onClick={() => handleProjectClick(project)}
                  style={{ cursor: 'pointer', }}
                >
                  {project.name}
                </p>
              ))}
            </>
          )}
        </Box>
      </div>
    </>
  );
}

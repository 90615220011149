import { BounceLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Button, Divider, Grid, IconButton, TextField, Box, Container } from '@mui/material';
import { useFormControls } from './contactFormControls';
import "./contactUs.css";
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';
import contactBackground from "../../assets/images/contactBackground.png";
import { ReactComponent as PinIcon } from '../../assets/images/pinIcon.svg'; 
import { ReactComponent as PhoneIcon } from '../../assets/images/phoneIcon.svg'; 
import { ReactComponent as MailIcon } from '../../assets/images/mailIcon.svg'; 
import styled from 'styled-components';


const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100%;
  margin-top: 10vh;
  margin-bottom: 12vh;
  margin-right: 0 !important;
  margin-left: 0; 
  padding: 0px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const ContactHeading = styled.div`
font-family: "Righteous";
font-size: 5rem;
font-weight: 400;
line-height: 45px;
color: #000000;

@media screen and (max-width: 600px) {
  font-size: 3.125rem;
}
`;

const BackgroundImage = styled.div`
  flex: 1;
  width: 840px;
  background-image: url(${contactBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  filter: grayscale(100%);

  @media screen and (max-width: 600px) {
    width: 0;
    visibility: hidden;
  }
`;

const TranslatedGrid = styled(Grid)`
  transform: translateX(200px); 

  @media screen and (max-width: 500px) {
    width: 350px;
    transform: translateX(0px);
  }

  @media screen and (min-width: 530px) and (max-width: 898px) {
    width: 350px;
    transform: translateX(50%);
}

`;

const ContactInfo = styled.div`
  flex: 1;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-self: center;
`;

const ContactButton = styled.button`
  width: 180px;
  height: 64px;
  border-radius: 30px;
  background-color: #444444;
  color: #000000; 
  font-size: 22px; 
  font-family: "M PLUS Rounded 1c";
  font-weight: 800;
  z-index: 100;
  border-radius: 30px; 
  width: 10vw;
  height: 8vh;
  text-transform: capitalize;

  @media screen and (max-width: 600px) {
    width: 350px;
  }
`

const FocusedTextField = styled.div`
  border: 1px solid fff;
  border-radius: 0;
  & .MuiOutlinedInput-root.Mui-focused {
    border: solid 2px #7BC24F;
  }

  & .MuiInputLabel-root.Mui-focused {
    color: #000;
  }
`;

const FormContainer = styled.div`
  border-radius: 50px;
  max-width: 688px;
  width: 70%;
  height: 55%;
  transform: translateX(450px);
  z-index: 100;
  display: flex;
  justify-self: center;

  @media screen and (max-width: 898px) {
    width: 80%;
    transform: translateX(0%);
    margin: auto;
  }
`;

const ContactInfoText = styled.div`
  font-family: "M PLUS Rounded 1c";
  font-size: 22px;
  font-weight: 400;
  line-height: 45px;
  color: #000000;

  @media screen and (max-width: 898px) {
    font-size: 16px;
  }
  
`;

const FormHeading = styled.div`
  font-family: "M PLUS Rounded 1c";
  font-size: 2rem;
  font-weight: 800;
  line-height: 60px;
  color: #000000;
  margin-top: 3vh;
`;


const useStyles = makeStyles((theme) => ({
  contactContainer: {
    display: 'grid',
    gridTemplateColumns: "1fr 1fr",
    width: '100%',
    marginTop: "12vh",
    marginBottom: "12vh"
  },
  backgroundImage: {
    flex: 1,
    backgroundImage: `url(${contactBackground})`,
    backgroundSize: 'contain', // Adjusted to 'contain'
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  contactInfo: {
    flex: 1,
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  focusedTextField: {
    border: "1px solid #fff",
    borderRadius: 0,
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: 'solid 2px #7BC24F'
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#000'
    },
  },
  formContainer: {
    borderRadius: "50px",
  },
}));



export function ContactUs() {
  const customStyle = {
    color: 'blue',
    fontSize: '24px',
  };

  const classes = useStyles();
  

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    const getData = async () => {
      setIsLoaded(true)

    }
    getData()
  }, [])

  const inputFieldValues = [
    {
      name: "first_name",
      label: "Name",
      id: "my-name",
      width: "100%",
      padding: "5%"
    },
    {
      name: "last_name",
      label: "Last Name",
      id: "my-lastname",
      width: "100%"
    },
    {
      name: "email",
      label: "Email",
      id: "my-email",
      width: "100%"
    },
    {
      name: "title",
      label: "Title",
      id: "my-title",
      width: "100%"
    },
    {
      name: "message",
      label: "Message",
      id: "my-message",
      width: "100%",
      multiline: true,
      rows: 4
    },
    {
      name: "company_name",
      label: "Company Name",
      id: "company_name",
      width: "100%"
    },
    
  ];

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values
  } = useFormControls();

  return (
    <>
      {isLoaded ? (
        <>
          <Container maxWidth="xl" sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        justifyContent: 'center',
        width: '100%',
        marginTop: '10vh',
        marginBottom: '12vh',
        marginRight: '0 !important',
        marginLeft: 0,
        padding: 0,
        '@media screen and (max-width: 898px)': {
          gridTemplateColumns: '1fr',
        },
      }}>

            <ContactInfo>
              <TranslatedGrid sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "6vh", marginTop: "6vh"}}>
                <ContactHeading style={{ marginBottom: "6vh"}}>GET IN <br></br><br></br>TOUCH</ContactHeading>

                <Grid item sx={{ display: "flex", flexDirection: "row", width: {xs: "85%", sm: "80%"}, marginTop: "2vh", alignItems: "center"}}>
                  <PinIcon style={{ width: '60px', height: '40px', marginRight: "0px" }} />
                  <ContactInfoText>
                    Plot 161, Hallgate, Nigel, 1490
                  </ContactInfoText>
                </Grid>

                <Grid item sx={{ display: "flex", flexDirection: "row", width: {xs: "85%", sm: "80%"}, marginTop: "2vh", alignItems: "center"}}>
                  <PhoneIcon style={{ width: '60px', height: '40px', marginRight: "14px" }} />
                  <div>
                    <ContactInfoText>
                      082 414 2858
                    </ContactInfoText>

                    <ContactInfoText>
                      083 280 9079
                    </ContactInfoText>
                  </div>
                </Grid>

                <Grid item sx={{ display: "flex", flexDirection: "row", width: {xs: "85%", sm: "80%"}, marginTop: "2vh", alignItems: "center"}}>
                  <MailIcon style={{ width: '40px', height: '40px', marginRight: "14px" }} />
                  <div>
                    <ContactInfoText>
                      corne@woodstockie.com
                    </ContactInfoText>

                    <ContactInfoText>
                      sales1@woodstockie.com
                    </ContactInfoText>
                  </div>
                </Grid>
              </TranslatedGrid>

              <FormContainer style={{  backgroundColor: "#86C350", display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center"}}>
                <FormHeading>Feel free to reach out</FormHeading>
                <form autoComplete="off"
                  onSubmit={handleFormSubmit}
                  >
                    {inputFieldValues.map((inputFieldValue, index) => {
                      return (
                        <TextField className={classes.focusedTextField}
                          key={index}
                          onChange={handleInputValue}
                          onBlur={handleInputValue}
                          name={inputFieldValue.name}
                          label={inputFieldValue.label}
                          error={errors[inputFieldValue.name]}
                          style={{ width: (inputFieldValue.width), paddingBottom: "5%" }}
                          value={(inputFieldValue.name in values) ? values[inputFieldValue.name] : undefined}
                          rows={inputFieldValue.rows ?? 1}
                          autoComplete="none"
                          {...(errors[inputFieldValue.name] && {
                            error: true,
                            helperText: errors[inputFieldValue.name]
                          })}
                          inputProps={{
                            style: { backgroundColor: "white", width: "34vw", border: "solid #fff 0.1vw", borderRadius: 0 }, // Set the text color to white
                          }}
                        />
                      );
                    })}
                    <br /><br />
                    <div style={{ display: "flex", justifyContent: "right"}}>
                      <ContactButton
                        disabled={!formIsValid()}
                      >
                        SEND
                      </ContactButton>
                    </div>
                    <br /><br />
                </form>
              </FormContainer>
            </ContactInfo>
            
            <ContactInfo>
              <BackgroundImage />
            </ContactInfo>
          </Container>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><BounceLoader color="#8dc74a" cssOverride={{}} loading size={100} /></div>

        </div>
      )
      }
    </>


  );
}

import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container>
            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Last Updated: 21 July 2024
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Introduction
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Welcome to Woodstockie ("we," "our," or "us"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website https://woodstockie.com/ (the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Information We Collect
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We collect personal information that you voluntarily provide to us when you register on the Site, express an interest in obtaining information about us or our products and services, when you participate in activities on the Site, or otherwise when you contact us.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The personal information that we collect depends on the context of your interactions with us and the Site, the choices you make, and the products and features you use. The personal information we collect may include the following:
                </Typography>
                <ul>
                    <Typography component="li" variant="body1" gutterBottom>Name</Typography>
                    <Typography component="li" variant="body1" gutterBottom>Email Address</Typography>
                    <Typography component="li" variant="body1" gutterBottom>Phone Number</Typography>
                    <Typography component="li" variant="body1" gutterBottom>Any other information you voluntarily provide</Typography>
                </ul>

                <Typography variant="h5" gutterBottom>
                    3. Use of Your Information
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We use the information we collect or receive:
                </Typography>
                <ul>
                    <Typography component="li" variant="body1" gutterBottom>
                        To send you marketing communications. We may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences.
                    </Typography>
                    <Typography component="li" variant="body1" gutterBottom>
                        To send administrative information to you. We may use your personal information to send you product, service, and new feature information and/or information about changes to our terms, conditions, and policies.
                    </Typography>
                    <Typography component="li" variant="body1" gutterBottom>
                        To respond to user inquiries/offer support to users. We may use your personal information to respond to your inquiries and solve any potential issues you might have with the use of our Services.
                    </Typography>
                </ul>

                <Typography variant="h5" gutterBottom>
                    4. Sharing Your Information
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We do not share your personal information with third parties except in the following circumstances:
                </Typography>
                <ul>
                    <Typography component="li" variant="body1" gutterBottom>
                        Legal Requirements. We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                    </Typography>
                    <Typography component="li" variant="body1" gutterBottom>
                        Protection of Rights. We may disclose information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person, and illegal activities, or as evidence in litigation in which we are involved.
                    </Typography>
                </ul>

                <Typography variant="h5" gutterBottom>
                    5. Security of Your Information
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Your Privacy Rights
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Depending on your location, you may have the following rights regarding your personal information:
                </Typography>
                <ul>
                    <Typography component="li" variant="body1" gutterBottom>The right to access – You have the right to request copies of your personal information.</Typography>
                    <Typography component="li" variant="body1" gutterBottom>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</Typography>
                    <Typography component="li" variant="body1" gutterBottom>The right to erasure – You have the right to request that we erase your personal information, under certain conditions.</Typography>
                    <Typography component="li" variant="body1" gutterBottom>The right to restrict processing – You have the right to request that we restrict the processing of your personal information, under certain conditions.</Typography>
                    <Typography component="li" variant="body1" gutterBottom>The right to object to processing – You have the right to object to our processing of your personal information, under certain conditions.</Typography>
                    <Typography component="li" variant="body1" gutterBottom>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</Typography>
                </ul>

                <Typography variant="h5" gutterBottom>
                    7. Contact Us
                </Typography>
                <Typography variant="body1" gutterBottom>
                    If you have any questions or comments about this policy, the ways in which we collect and use your information, your privacy rights, or wish to exercise your privacy rights, please do not hesitate to contact us at:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Woodstockie <br />
                    info@woodstockie.com <br />
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Changes to This Policy
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;

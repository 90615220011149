// components/Home.js
//CSS
import "./storage.css";


//IMAGES
import storageBackground from "../../assets/woodstockie/store/store1.webp";
import storageImage1 from '../../assets/images/storageImage1.png';
import storageImage2 from '../../assets/images/storageImage2.png';
import storageImage3 from '../../assets/images/storageImage3.png';
import storageImage4 from '../../assets/images/storageImage4.png';
import storageImage5 from '../../assets/images/storageImage5.png';

//MUI COMPONENTS
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import { css } from '@emotion/react';
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styled from '@emotion/styled';
import { Typography } from "@mui/material";


//OTHER COMPONENTS
import * as React from "react";
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import Maps from "../../components/maps/Maps";
import { SyncLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import SwipeableTextMobileStepper from '../home/carousel';
import { useEffect, useState } from "react";


const StyledPaper = styled(Paper)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#000000B2',
  textAlign: 'left',
  boxSizing: 'border-box',
  color: '#ffffff',
  wordWrap: 'break-word',
  fontWeight: 400
});

const StyledContainer = styled(Paper)({
  position: 'relative',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url('${storageBackground}')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'grayscale(100%)',
  padding: 0
});


const BannerText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'Righteous',
  fontSize: '100px',
  fontWeight: 400,
  margin: '0 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '2.5rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '3rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const BannerSubText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'M PLUS Rounded 1c',
  fontSize: '40px',
  fontWeight: 400,
  margin: '1vh 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.25rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '2rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const LandingSectionStyles = styled.div`
  background-image: url(${storageBackground});
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat; 
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 1vh auto;
  filter: grayscale(100%);
`;

const PageHeading = styled('h1')({
  color: "#4EA908",
  fontFamily: "Righteous",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "49.67px",
  margin: "4vh 8vw"
})

const InfoHeading = styled('h6')({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "28px",
  fontStyle: "normal",
  fontWeight: 800,
  margin: "4vh auto"
})

const InfoText = styled('p')({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 400,
  margin: "4vh auto",
  lineHeight: "35px",
})

const QuoteButton = styled(Button)({
  backgroundColor: "#4EA908",
  padding: '12px 32px',
  fontSize: '34px',
  borderRadius: "80px",
  color: "#000000",
  fontFamily: "Righteous",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "62px",
  textAlign: "center",
  margin: "4vh auto",
  textTransform: "capitalize",
  '&:hover': {
    backgroundColor: '#7BC24F',
    color: '#fff'
}
})

const EndText = styled('h1')({
  color: "#000000",
  fontFamily: "Righteous",
  fontSize: "36px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "44px",
  margin: "4vh auto",
  '@media(max-width: 1000px)': {
    margin: "2vh 8vw"
  },
})

const storageImages = [
  { label: 'Security Center', imgPath: storageImage1 },
  { label: 'Furnished storage unit', imgPath: storageImage2 },
  { label: 'Unfurnished storage unit', imgPath: storageImage3 },
  { label: 'Storage unit', imgPath: storageImage4 },
  { label: 'Airconditioned storage unit', imgPath: storageImage5 },
];


export function Storage() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
    
      <Grid container>
        <StyledContainer>
          <StyledPaper>
            <BannerText>STORAGE UNITS</BannerText>
            <BannerSubText>Elevating your peace of mind with our secure storage units</BannerSubText>
          </StyledPaper>
        </StyledContainer>


        <Grid container spacing={3} sx={{ display: "flex", justifyContent: "space-around"}}>

          {/* First Column */}
          <Grid item xs={10} md={3} mt={8}>
              <InfoHeading>Cutting edge security</InfoHeading>
              <InfoText>
              Our storage units are equipped with top-notch security features to ensure the protection of your valuable possessions
              </InfoText>
          </Grid>

          {/* Second Column */}
          <Grid item xs={10} md={3} mt={8}>
              <InfoHeading>Versatile solutions</InfoHeading>
              <InfoText>
                Whether you're storing seasonal items or long-term valuables, our storage units offer the flexibility to meet your diverse needs
              </InfoText>
          </Grid>

          {/* Third Column */}
          <Grid item xs={10} md={3} mt={8}>
              <InfoHeading>Convenient access</InfoHeading>
              <InfoText>
                Our user-friendly approach provides you with easy access to your stored items whenever you need them
              </InfoText>
          </Grid>
        </Grid>
        
        <Grid container>
          <PageHeading>
            Experience the assurance of knowing your belongings are safeguarded by state-of-the-art security measures, enabling you to store with utmost confidence
          </PageHeading>
        </Grid>

        <Grid container>
          <SwipeableTextMobileStepper images={storageImages} />;
        </Grid>

        <Grid container>
          <EndText>
            Experience a new level of convenience and protection with Woodstock Projects
          </EndText>
        </Grid>

        <Grid container>
          <QuoteButton>Get a Quote</QuoteButton>
        </Grid>
      </Grid>

    </>
  );
}

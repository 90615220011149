import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { markers } from './project_data'; // Update path as necessary
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const ProjectPage = () => {
    const { country_code, project_id } = useParams();
    const navigate = useNavigate(); // Hook for navigation

    // Find the marker based on country_code and project_id
    const project = markers.find(marker => marker.country_code === country_code && marker.id === parseInt(project_id, 10));

    if (!project) {
        return <div>Project not found</div>;
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => navigate(-1)} style={{ marginRight: '10px' }}>
                    <ArrowBackIcon />
                </IconButton>
                <h1>{project.name}</h1>
            </div>
            <img src={project.imageUrl} alt={project.name} style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }} />
            <p>{project.description}</p>
            <div dangerouslySetInnerHTML={{ __html: project.longDescription }} />
        </div>
    );
};

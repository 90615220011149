// components/Home.js

//CSS
import "./container.css";


//IMAGES
import backgroundImage from "../../assets/woodstockie/containers/container.jpg";
import containerImage1 from '../../assets/images/containerImage1.png';
import containerImage2 from '../../assets/images/containerImage2.png';
import containerImage3 from '../../assets/images/containerImage3.png';
import containerImage4 from '../../assets/images/containerImage4.png';
import containerImage5 from '../../assets/images/containerImage5.png';
import containerImage6 from '../../assets/images/containerImage6.png';
import containerImage7 from '../../assets/images/containerImage7.png';


//ICONS
import trainingRoomGreyIcon from "../../../src/assets/greyIcons/trainingRoomGreyIcon.svg";
import aluminiumGreyIcon from "../../assets/greyIcons/aluminiumGreyIcon.svg";
import ablutionsGreyIcon from "../../assets/greyIcons/ablutionsGreyIcon.svg";
import controlRoomGreyIcon from "../../assets/greyIcons/controlRoomGreyIcon.svg";
import modernHomeGreyIcon from "../../assets/greyIcons/modernHomeGreyIcon.svg";
import officeGreyIcon from "../../assets/greyIcons/officeGreyIcon.svg";
import restaurantGreyIcon from "../../assets/greyIcons/restaurantGreyIcon.svg";
import storageUnitGreyIcon from "../../assets/greyIcons/storageUnitGreyIcon.svg";


//MUI COMPONENTS
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import { css } from "@emotion/react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";


//OTHER COMPONENTS
import * as React from "react";
import { Heading } from "@aws-amplify/ui-react";
import { useParams } from "react-router-dom";
import Maps from "../../components/maps/Maps";
import { SyncLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import SwipeableTextMobileStepper from "../home/carousel";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";



const StyledPaper = styled(Paper)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#000000B2',
  textAlign: 'left',
  boxSizing: 'border-box',
  color: '#ffffff',
  wordWrap: 'break-word',
  fontWeight: 400
});

const StyledContainer = styled(Paper)({
  position: 'relative',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url('${backgroundImage}')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'grayscale(100%)',
  padding: 0
});


const BannerText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'Righteous',
  fontSize: '100px',
  fontWeight: 400,
  margin: '0 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '2.5rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '3rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const BannerSubText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'M PLUS Rounded 1c',
  fontSize: '40px',
  fontWeight: 400,
  margin: '1vh 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.25rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '2rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const LandingSectionStyles = styled.div`
  background-image: url(${backgroundImage});
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat; 
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 1vh auto;
  filter: grayscale(100%);
`;

const PageHeading = styled("h1")({
  color: "#4EA908",
  fontFamily: "Righteous",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "49.67px",
  margin: "4vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.5rem',
    margin: 'auto',
    textAlign: 'center',
    padding: "0 2vw"
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '1.8rem',
    margin: 'auto',
    textAlign: 'center',
    padding: "0 2vw"
  },
});

const InfoHeading = styled("h6")({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "28px",
  fontStyle: "normal",
  fontWeight: 800,
  margin: "4vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.375rem',
    margin: 'auto',
    textAlign: 'center'
  },
});

const InfoText = styled("p")({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 400,
  margin: "4vh auto",
  lineHeight: "35px",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.2rem',
  },
});

const QuoteButton = styled(Button)({
  backgroundColor: "#4EA908",
  padding: "12px 32px",
  fontSize: "34px",
  borderRadius: "80px",
  color: "#000000",
  fontFamily: "Righteous",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "62px",
  textAlign: "center",
  margin: "8vh auto",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#7BC24F",
    color: "#fff",
  },
});

const EndText = styled("h1")({
  color: "#000000",
  fontFamily: "Righteous",
  fontSize: "36px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "44px",
  margin: "2vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: "1.2rem",
    margin: "auto",
    textAlign: "center",
    padding: "2vh 2vw"
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '1.4rem',
    margin: '2vh auto',
    textAlign: 'center',
    padding: "0 2vw"
  },
});

const ServicesText = styled(Typography)({
  color: "#666666",
  fontFamily: "Droid Sans",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "35.14px",
  marginTop: "2vh"
})

const containerImages = [
  { label: 'Living spaces', imgPath: containerImage1 },
  { label: 'Boardrooms', imgPath: containerImage2 },
  { label: 'Patios', imgPath: containerImage3 },
  { label: 'Balconies', imgPath: containerImage4 },
  { label: 'Workrooms', imgPath: containerImage5 },
  { label: 'Pop-up Stores', imgPath: containerImage6 },
  { label: 'Temporary set-ups', imgPath: containerImage7 },
];


export function WContainer() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Grid container>
        <StyledContainer>
          <StyledPaper>
            <BannerText>CONTAINERS</BannerText>
            <BannerSubText>Transforming standard containers into dynamic spaces</BannerSubText>
          </StyledPaper>
        </StyledContainer>

        <Grid container>
          <PageHeading>
            We offer tailored solutions that redefine versatility and innovation
            through:
          </PageHeading>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{
            display: "grid",
            gridTemplateColumns: {xs: "1fr", md: "1fr 1fr"},
            justifyItems: "center",
            margin: "4vh auto",
          }}
        >
          {/* First Column */}
          <Grid item xs={11} md={7}>
            <InfoHeading>Custom conversions</InfoHeading>
            <InfoText>
              Our expertise lies in converting containers into a wide range of
              functional spaces. from kitchen units to classrooms
            </InfoText>
          </Grid>

          {/* Second Column */}
          <Grid item xs={11} md={7}>
            <InfoHeading>Creative configurations</InfoHeading>
            <InfoText>
              Experience the freedom to design your ideal space by leveraging
              the modular nature of containers
            </InfoText>
          </Grid>

          {/* Row 2 */}
          <Grid item xs={11} md={7}>
            <InfoHeading>Quality and affordability</InfoHeading>
            <InfoText>
              Benefit from our commitment to using high-quality materials and
              delivering exceptional craftsmanship at competitive prices
            </InfoText>
          </Grid>

          <Grid item xs={11} md={7}>
            <InfoHeading>Endlesss applications</InfoHeading>
            <InfoText>
              Whether it's offices, accommodation, or storage facilities, our
              container solutions cater to diverse needs
            </InfoText>
          </Grid>
        </Grid>


        <Grid container className="servicesIcons" sx={{ display: "grid", gridTemplateRows: "1fr 1fr", backgroundColor: "#fff", padding: {xs: "0 2vw", md: 0 } }}>

          <Grid container sx={{width: "100%", display: "grid", gridTemplateColumns: {xs: "1fr 1fr", sm: "1fr 1fr 1fr 1fr" }, justifyContent: "space-around", alignItems: "center", marginY: "4vh", textAlign: "center"}}> 
            <Grid item mt={2}>
              <Link to="/storage" style={{ textDecoration: "none"}}>
                <img src={storageUnitGreyIcon} alt="Storage Units Grey Icon" width={"50px"} height={"50px"} />
                <ServicesText>Storage Units</ServicesText>
              </Link>
            </Grid>

            <Grid item mt={2}>
              <Link to="/restaurants" style={{ textDecoration: "none"}}>
                <img src={restaurantGreyIcon} alt="Restaurants Grey Icon" width={"50px"} height={"50px"} />
                <ServicesText>Restaurants</ServicesText>
              </Link>
            </Grid>

            <Grid item mt={2}>
              <Link to="/offices" style={{ textDecoration: "none"}}>
                <img src={officeGreyIcon} alt="Offices Grey Icon" width={"50px"} height={"50px"} />
                <ServicesText>Offices</ServicesText>
              </Link>
            </Grid>

            <Grid item mt={2}>
              <Link to="/modernHomes" style={{ textDecoration: "none"}}>
                <img src={modernHomeGreyIcon} alt="Modern Homes Grey Icon" width={"50px"} height={"50px"} />
                <ServicesText>Modern Homes</ServicesText>
              </Link>
            </Grid>
          </Grid>

          <Grid container sx={{width: "100%", display: "grid", gridTemplateColumns: {xs: "1fr 1fr", sm: "1fr 1fr 1fr 1fr" }, justifyContent: "space-evenly",alignItems: "center", marginY: "4vh", textAlign: "center"}}>
            <Grid item mt={2}>
              <Link to="/control_room" style={{ textDecoration: "none"}}>
                <img src={controlRoomGreyIcon} alt="Control Room Grey Icon" width={"50px"} height={"50px"} />
                <ServicesText>Control Rooms</ServicesText>
              </Link>
            </Grid>

            <Grid item mt={2}>
              <Link to="/ablutions" style={{ textDecoration: "none"}}>
                <img src={ablutionsGreyIcon} alt="Ablutions Grey Icon" width={"50px"} height={"50px"} />
                <ServicesText>Ablutions</ServicesText>
              </Link>
            </Grid>

            <Grid item mt={2}>
              <Link to="/classrooms" style={{ textDecoration: "none"}}>
                <img src={trainingRoomGreyIcon} alt="Training Room Grey Icon" width={"50px"} height={"50px"} />
                <ServicesText>Training Rooms</ServicesText>
              </Link>
            </Grid>

            <Grid item mt={2}>
              <Link to="/aluminium" style={{ textDecoration: "none"}}>
                <img src={aluminiumGreyIcon} alt="Aluminium Grey Icon" width={"50px"} height={"50px"} />
                <ServicesText>Aluminium</ServicesText>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <SwipeableTextMobileStepper images={containerImages} />;
        </Grid>

        <Grid container sx={{display: "flex", flexDirection: "column"}}>
          <EndText>Embark on a journey of innovation.</EndText>

          <EndText>
            Woodstock Projects is your partner in turning concepts into reality
          </EndText>
        </Grid>
      </Grid>
    </>
  );
}

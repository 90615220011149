import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Container>
            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Terms and Conditions
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Last Updated: 21 July 2024
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Introduction
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Welcome to Woodstockie ("Company", "we", "our", "us")! These Terms and Conditions ("Terms", "Terms and Conditions") govern your use of our website located at https://woodstockie.com/ (together or individually "Service") operated by Woodstockie.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here 
                    <Link href="/privacy" color="#8DC74A">Privacy Policy</Link>.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Your agreement with us includes these Terms and our Privacy Policy ("Agreements"). You acknowledge that you have read and understood Agreements, and agree to be bound of them.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Communications
                </Typography>
                <Typography variant="body1" gutterBottom>
                    By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at 
                    <Link href="mailto:subscriptions@woodstockie.com" color="#8DC74A">
                        &nbsp;subscriptions@woodstockie.com
                    </Link>.
                </Typography>

                
                <Typography variant="h5" gutterBottom>
                    4. Content
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Content found on or through this Service are the property of Woodstockie or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    5. Prohibited Uses
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
                </Typography>
                <ul>
                    <Typography component="li" variant="body1" gutterBottom>
                        In any way that violates any applicable national or international law or regulation.
                    </Typography>
                    <Typography component="li" variant="body1" gutterBottom>
                        For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
                    </Typography>
                    <Typography component="li" variant="body1" gutterBottom>
                        To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.
                    </Typography>
                    <Typography component="li" variant="body1" gutterBottom>
                        To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
                    </Typography>
                    <Typography component="li" variant="body1" gutterBottom>
                        In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
                    </Typography>
                    <Typography component="li" variant="body1" gutterBottom>
                        To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.
                    </Typography>
                </ul>

                <Typography variant="h5" gutterBottom>
                    6. Termination
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    If you wish to terminate your account, you may simply discontinue using Service.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Limitation of Liability
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Except as prohibited by law, you will hold us and our officers, directors, employees, and agents harmless for any indirect, punitive, special, incidental, or consequential damage, however it arises (including attorneys' fees and all related costs and expenses of litigation and arbitration), whether in an action of contract, negligence, or other tortious action, or arising out of or in connection with this agreement, including without limitation any claim for personal injury or property damage, arising from this agreement and any violation by you of any federal, state, or local laws, statutes, rules, or regulations, even if Company has been previously advised of the possibility of such damage. Except as prohibited by law, if there is liability found on the part of Company, it will be limited to the amount paid for the products and/or services, and under no circumstances will there be consequential or punitive damages.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Governing Law
                </Typography>
                <Typography variant="body1" gutterBottom>
                    These Terms shall be governed and construed in accordance with the laws of South Africa, which governing law applies to agreement without regard to its conflict of law provisions.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Changes to Service
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    9. Amendments to Terms
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    10. Acknowledgement
                </Typography>
                <Typography variant="body1" gutterBottom>
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
                </Typography>
            </Box>
        </Container>
    );
};

export default TermsAndConditions;

import { ContactUs } from "./pages/contact/contactUs";
import { FAQ } from "./pages/faq/faq";
import { Home } from "./pages/home/home";
import { Aluminium } from "./pages/aluminium/aluminium";
import { WContainer } from "./pages/container/container";
import { Storage } from "./pages/storage/storage";
import { Protected } from './pages/userHome/userHome';
import { ModernHouse } from "./pages/modernHomes/modernHomes";
import { ControlRoom } from "./pages/control_room/control_room";
import { Offices } from "./pages/offices/offices";
import { Restaurants } from "./pages/restaurants/restaurants";
import { Ablutions } from "./pages/ablutions/ablutions";
import { Classrooms } from "./pages/classrooms/classrooms";
import { SignIn } from "./components/auth/signin/signin";
import { SignUp } from "./components/auth/signup/signup";
import { GenerateQuote } from "./pages/userHome/generate";
import { Forgot } from "./components/auth/password-management/forgot/forgot";
import { Invoices } from "./pages/userHome/invoices";
import { AddBusiness } from "./pages/userHome/addBusiness";
import { BusinessHome } from "./pages/userHome/businessHome";
import { ProjectMap } from "./pages/project_map/project_map";
import { ProjectCountry } from "./pages/project_map/project_country";
import { ProjectPage } from "./pages/project_map/project";
import PrivacyPolicy from "./pages/legal/Privacy";
import TermsAndConditions from "./pages/legal/Terms";

export interface AppRoute{
    Navigation: string,
    DisplayName: string,
    Protected: boolean,
    Component: () => JSX.Element,
    NavBarVisibile: boolean
}

export const CustomRoutes : AppRoute[] = [
    {
        DisplayName: 'Home',
        Navigation: '/home',
        Protected: false,
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Containers',
        Navigation: '/containers',
        Protected: false,
        Component: WContainer,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Units',
        Navigation: '/storage',
        Protected: false,
        Component: Storage,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Aluminium',
        Navigation: '/aluminium',
        Protected: false,
        Component: Aluminium,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Contact',
        Navigation: '/contact-us',
        Protected: false,
        Component: ContactUs,
        NavBarVisibile: true
    },
    {
        DisplayName: 'FAQ',
        Navigation: '/faq',
        Protected: false,
        Component: FAQ,
        NavBarVisibile: true
    },
    {
        DisplayName: 'modernHouse',
        Navigation: '/modernHomes',
        Protected: false,
        Component: ModernHouse,
        NavBarVisibile: false
    },
    {
        DisplayName: 'control_room',
        Navigation: '/control_room',
        Protected: false,
        Component: ControlRoom,
        NavBarVisibile: false
    },
    {
        DisplayName: 'offices',
        Navigation: '/offices',
        Protected: false,
        Component: Offices,
        NavBarVisibile: false
    },
    {
        DisplayName: 'restaurants',
        Navigation: '/restaurants',
        Protected: false,
        Component: Restaurants,
        NavBarVisibile: false
    },
    {
        DisplayName: 'ablutions',
        Navigation: '/ablutions',
        Protected: false,
        Component: Ablutions,
        NavBarVisibile: false
    },
    {
        DisplayName: 'classrooms',
        Navigation: '/classrooms',
        Protected: false,
        Component: Classrooms,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Sign up',
        Navigation: '/signup',
        Protected: false,
        Component: SignUp,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Forgot',
        Navigation: '/forgot',
        Protected: false,
        Component: Forgot,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Business',
        Navigation: '/business/:id',
        Protected: true,
        Component: BusinessHome,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Project Map',
        Navigation: '/projects',
        Protected: false,
        Component: ProjectMap,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Project Map',
        Navigation: '/projects/:country_code',
        Protected: false,
        Component: ProjectCountry,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Project Map',
        Navigation: '/projects/:country_code/:project_id',
        Protected: false,
        Component: ProjectPage,
        NavBarVisibile: false
    },
    // Privacy and terms
    {
        DisplayName: 'Privacy',
        Navigation: '/privacy',
        Protected: false,
        Component: PrivacyPolicy,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Terms',
        Navigation: '/terms',
        Protected: false,
        Component: TermsAndConditions,
        NavBarVisibile: false
    },
]

export const SettingRoutes : AppRoute[] = [
    {
        DisplayName: 'Profile',
        Navigation: '/profile',
        Protected: true,
        Component: Protected,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Generate Quote',
        Navigation: '/quote',
        Protected: true,
        Component: GenerateQuote,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Invoices',
        Navigation: '/invoices',
        Protected: true,
        Component: Invoices,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Add Business',
        Navigation: '/addBusiness',
        Protected: true,
        Component: AddBusiness,
        NavBarVisibile: true
    }
]
// components/Protected.js
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { SyncLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import { getItem } from '../../services/api.svs';
import { Button } from '@mui/material';
import { styled } from "@mui/material/styles";

const ProfileHeadings = styled('h4')({
  color: "#000",
  textAlign: "center",
  fontFamily: "Righteous",
  fontSize: "2rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "0vh auto 8vh auto"
})

export function Invoices() {
  const { route } = useAuthenticator((context) => [context.route]);


  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")
  const [items, setItems] = useState([])

  function handleDownload(row: any): void {
    console.log("Downloading", row)
  }

  useEffect(() => {
    const getData = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('quote', token).then(resp => {
        console.log(resp.data)
        setItems(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        console.log(resp)
        setItems([])
        setIsLoaded(true)
        setNotificationID(Store.addNotification({
          title: "Sorry!",
          message: "We encountered an issue, but the team will look at this. ",
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        }));

        Store.removeNotification(notificationID)
      })
      setIsLoaded(true)
    }
    getData()
  }, [])

  const greenTheme = createTheme({
    palette: {
      primary: {
        main: '#b9f6ca', // Green color
      },
    },
  });

  const columns: GridColDef[] = [
    { field: 'email_to', headerName: 'Email To', flex: 1 },
    { field: 'address', headerName: 'Address', flex: 1 },
    { field: 'address2', headerName: 'Address 2', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: any) => (
        <>
          {params.row.is_status !== "pending" && (
            <Button variant="contained" color="primary" onClick={() => handleDownload(params.row.quote_id)}>
              Download
            </Button>
          )
          }
        </>

      ),
    },
  ];


  
  return (
    <>
      {isLoaded ? (
        <>
            <Grid 
                mt={18}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
            >
              <Grid container>
                <ProfileHeadings>Your current invoices</ProfileHeadings>
              </Grid>

              <Grid container xs={8} sx={{ textAlign: "center", margin: "auto" }}>
                <DataGrid columns={columns} rows={items} getRowId={(row: any) => row.quote_id} sx={{ minHeight: "30vh" }} />
              </Grid>
            </Grid>

        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><SyncLoader color="#36d7b7" /></div>

        </div>
      )}
    </>


  );
}


import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Auth } from 'aws-amplify';
import "./signup.css";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { Box, Button, Grid, Input, Snackbar, TextField, Typography } from "@mui/material";

import { postItem } from "../../../services/api.svs";
// import '../../../../node_modules/react-phone-number-input/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/material/styles";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/types.cjs";
import 'react-phone-number-input/style.css'


const useStyles = makeStyles({
    textField: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#86c350',
                borderWidth: '2px'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#86c350',
                borderWidth: '2x'
            },
            '&.Mui-focused:hover fieldset': {
                borderColor: '#86c350',
                borderWidth: '2px'
            },
        },
    },
});

const SignUpHeading = styled('h4')({
    color: "#000",
    textAlign: "center",
    fontFamily: "Righteous",
    fontSize: "2.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
    marginTop: "2vh"
})

const SignUpText = styled('h6')({
    color: "#000",
    fontFamily: "Rounded Mplus 1c",
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "left",
    margin: "1vh auto"
})



export function SignUp() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("")
    const [verificationCode, setVerificationCode] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [errors, setErrors] = useState({
        name: "",
        lastName: "",
        username: "",
        password: "",
        phone: "",
        verificationCode: "",
    });

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);

    const [tAndC, setTAndC] = useState(false);

    const handleSetTAndC = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setTAndC(event.target.checked)
    };

    const alertAction = () => setOpenAlert(false);

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setter(event.currentTarget.value);
        validateField(event.currentTarget.name, event.currentTarget.value);
    };

    const handleVerificationCode = (event: { currentTarget: { value: SetStateAction<string>; }; }) => {
        setVerificationCode(event.currentTarget.value)
    };

    const handlePassword = (event: { currentTarget: { value: SetStateAction<string>; }; }) => {
        setPassword(event.currentTarget.value)

    };

    const [alertType, setAlertType] = useState("success");
    const [alertIcon, setAlertIcon] = useState("");
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");

    const openAlertFunction = (type: SetStateAction<string>, title: SetStateAction<string>, message: SetStateAction<string>) => {
        setAlertType(type)
        setAlertTitle(title)
        setAlertMessage(message)
        setOpenAlert(true)

    };

    const renderAlert = (
        <Snackbar
            color={alertType}
            title={alertTitle}
            message={alertMessage}
            open={openAlert}
            onClose={alertAction}
            sx={{ background: 'white' }}
        />
    );

    const handlePhoneChange = (setPhone: Dispatch<SetStateAction<string>>) => (value?: E164Number) => {
        setPhone(value || ""); // Convert undefined to an empty string
    };

    const validateField = (field: any, value: any) => {
        let error = "";
        switch (field) {
            case "name":
                error = value ? "" : "First name is required.";
                break;
            case "lastName":
                error = value ? "" : "Last name is required.";
                break;
            case "username":
                error = /\S+@\S+\.\S+/.test(value) ? "" : "Valid email is required.";
                break;
            case "password":
                error = value.length >= 6 ? "" : "Password must be at least 6 characters.";
                break;
            case "phone":
                error = value ? "" : "Phone number is required.";
                break;
            case "verificationCode":
                error = value.length === 6 ? "" : "Verification code must be 6 digits.";
                break;
            default:
                break;
        }
        setErrors((prev: any) => ({ ...prev, [field]: error }));
    };

    const verificationHandler = async () => {
        console.log("Setting", username)

        Auth.confirmSignUp(username, verificationCode)
            .then(async () => {
                // const token =  (await Auth.currentSession()).getIdToken().getJwtToken()
                console.log('verified');
                // const phone_number = phone.countryCode + "" + phone.phoneNumber.replaceAll(" ", "")
                let data = {
                    first_name: name,
                    last_name: last_name,
                    email: username,
                    phone_number: phone
                }

                postItem('client', data).then(resp => {
                    let state = "success"
                    navigate('/profile')
                }).catch((e) => {
                    let state = "danger"
                    console.log('failed with errork', e);
                })
            })
            .catch((e) => {
                console.log('already verified');
                const formData = new FormData();
                formData.append("first_name", name);
                console.log(name)
                formData.append("last_name", last_name);
                formData.append("email", username);
                formData.append("phone_number", phone);

                // console.log(formData.entries)

                let data = {
                    first_name: name,
                    last_name: last_name,
                    email: username,
                    phone_number: phone
                }

                // console.log(data)
                postItem('client', data).then(resp => {
                    let state = "success"
                    navigate('/profile')
                }).catch((e) => {
                    let state = "danger"
                    console.log('failed with errork', e);
                })
                console.log('failed with error', e);
            });
    }

    const signUpHandler = async () => {
        // setIsLoaded(false)
        if (!tAndC) {
            openAlertFunction("warning", "Accept T&C", "Please make sure to select our Terms and conditions")
        } else {
            console.log("Signing up")
            // console.log(phone.countryCode + "" + phone.phoneNumber.replaceAll(" ", ""))
            const phone_number = phone
            // console.log(phone_number)
            // console.log(formatPhoneNumber(phone_number))
            try {
                const { user } = await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        phone_number,
                        name
                    },
                    autoSignIn: { // optional - enables auto sign in after user is confirmed
                        enabled: true,
                    }
                });
                console.log(user);
                setCodeSent(true)
                openAlertFunction("success", "Your user has been created", "Redirecting you to your profile after verification")
            } catch (error) {
                console.log('error signing up:', error);
                openAlertFunction("error", "Sign up issue", "We could not create a user for you at the moment")
            }

        }
    }
    return (

        <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%', marginTop: { xs: "30%", lg: "20vh" }, marginBottom: { xs: "30%", lg: "20vh" } }}>
            <Card sx={{ width: "30%", minWidth: "250px", display: "flex", justifyContent: "center", flexDirection: "column", boxShadow: "0px 14px 18px rgba(0, 0, 0, 0.2)" }}>
                <Box sx={{ margin: "auto" }}>
                    <SignUpHeading>
                        Join us today
                    </SignUpHeading>

                    <SignUpText>
                        Enter your details to register
                    </SignUpText>
                </Box>

                {!codeSent ? (
                    <>
                        <Box pt={0} pb={3} px={0} sx={{ display: "flex", justifyContent: "center" }}>
                            <Box component="form" role="form">
                                <Box mb={2}>
                                    <TextField
                                        type="text"
                                        placeholder="First Name"
                                        fullWidth
                                        onChange={handleInputChange(setFirstName)}
                                        className={classes.textField}
                                        error={!!errors.name}
                                        helperText={errors.name} />
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        type="text"
                                        placeholder="Last Name"
                                        fullWidth onChange={handleInputChange(setLastName)}
                                        className={classes.textField}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName} />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        type="text"
                                        placeholder="Email"
                                        fullWidth
                                        onChange={handleInputChange(setUsername)}
                                        className={classes.textField}
                                        error={!!errors.username}
                                        helperText={errors.username} />
                                </Box>

                                <Box mb={2}>
                                    <PhoneInputWithCountrySelect
                                        country="ZA"
                                        value={phone}
                                        onChange={handlePhoneChange(setPhone)}
                                        style={{borderRadius: '3px', height: "6vh"}}
                                    />
                                </Box>

                                <Box mb={2}>
                                    <TextField type="password" placeholder="Password" fullWidth onChange={handlePassword} className={classes.textField} />
                                </Box>

                                <Box display="flex" alignItems="center" ml={-1}>
                                    <input type="checkbox" className="checkbox" onChange={handleSetTAndC} />

                                    <Typography
                                        variant="button"
                                        fontWeight="regular"
                                        // color="text"
                                        sx={{
                                            cursor: "pointer", userSelect: "none", ml: -1, fontWeight: 500
                                        }}
                                    >
                                        &nbsp;&nbsp;I agree&nbsp;
                                    </Typography>

                                    <Typography
                                        component="a"
                                        href="/terms"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="button"
                                        fontWeight="bold"
                                        color="warning"
                                        sx={{ textDecoration: "none" }}
                                    >
                                        Terms and Conditions
                                    </Typography>

                                </Box>

                                <Box mt={4} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button color="warning" fullWidth onClick={signUpHandler}
                                        sx={{
                                            backgroundColor: "#86C350", fontFamily: "Rounden Mplus 1c", fontWeight: 500, fontSize: "1.1rem", color: "#000", width: { xs: "25vw", sm: "10vw", lg: "8vw" }, height: "15%",
                                            '&:hover': {
                                                backgroundColor: "#86C350",
                                                color: "#fff",
                                                border: "solid 0.2vh #7BC24F",
                                                margin: "auto",
                                                padding: "0.6vh 1vw"
                                            }
                                        }}>
                                        sign up
                                    </Button>
                                </Box>

                                <Box mt={6} mb={3} textAlign="center">
                                    <Typography variant="button"
                                        sx={{
                                            color: "#000",
                                            fontFamily: "Rounded Mplus 1c",
                                            fontSize: "1.1rem",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "normal",
                                            textAlign: "left",
                                            width: "55%",
                                            marginTop: "1vh",
                                            marginBottom: "1vh"
                                        }}
                                    >
                                        Already have an account?{" "}
                                    </Typography>

                                    <Typography
                                        my={2}
                                        component={Link}
                                        to="/login"
                                        variant="button"
                                        sx={{
                                            textDecoration: "none",
                                            fontFamily: "Rounded Mplus 1c",
                                            fontStyle: "normal"
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: "1.1rem",
                                                color: "#7BC24F",
                                                "&: hover": {
                                                    backgroundColor: "#7BC24F",
                                                    color: "#fff"
                                                }
                                            }}
                                        >
                                            Sign In
                                        </Button>
                                    </Typography>

                                </Box>
                            </Box>

                        </Box>
                    </>
                ) : (
                    <>
                        <Box pt={4} pb={3} px={3}>
                            <Box component="form" role="form">
                                <Typography variant="button" color="text">
                                    We have sent you a code to verify your account</Typography>
                                <Box mb={2}>
                                    <Input type="text" placeholder="Verification Code" value={verificationCode} fullWidth onChange={handleVerificationCode} />
                                </Box>
                                <Box mt={4} mb={1}>
                                    <Button color="warning" fullWidth onClick={verificationHandler}>
                                        verify
                                    </Button>
                                </Box>
                                <Box mt={3} mb={1} textAlign="center">
                                    <Typography variant="button" color="text">
                                        Already have an account?{" "}
                                        <Typography
                                            component={Link}
                                            to="/authentication/sign-in"
                                            variant="button"
                                            color="warning"
                                            fontWeight="medium"
                                        // textGradient
                                        >
                                            Sign In
                                        </Typography>
                                    </Typography>
                                </Box>
                            </Box>

                        </Box>
                    </>
                )

                }
                {renderAlert}
            </Card>
        </Grid>

    );
}

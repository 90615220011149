import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from './footer/Footer';
import { useBanner } from '../context/BannerContext';
import NavBar from './navbar/Navbar';

interface BannerMessageProps {
  message: string;
}

const BannerMessage: React.FC<BannerMessageProps> = ({ message }) => {
  const bannerStyle: React.CSSProperties = {
    position: 'fixed',
    top: '10vh',
    overflow: 'hidden',
    height: '3rem',
    backgroundColor: '#fed7d7', 
    display: 'flex',
    alignItems: 'center',
    width: '100%', 
    zIndex: 1000,
  };

  const textStyle: React.CSSProperties = {
    whiteSpace: 'nowrap',
    fontSize: '1.25rem', 
    fontWeight: 'bold',
    color: '#e53e3e', 
    position: 'absolute',
    animation: 'scroll-left 20s linear infinite',
    width: '100%',
  };

  return (
    <div style={bannerStyle}>
      <div style={textStyle}>
        {message}
      </div>
      <style>
        {`
          @keyframes scroll-left {
            0% {
              transform: translateX(110%);
            }
            100% {
              transform: translateX(-10%);
            }
          }
        `}
      </style>
    </div>
  );
};

export const Layout: React.FC = () => {
  const banner = useBanner();

  return (
    <>
      <NavBar />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '1 0 auto', marginTop: '10vh', width: '100%' }}>
          {banner && (
            <BannerMessage message={banner} />
          )}
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

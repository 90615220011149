// components/Protected.js
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { SyncLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Avatar, Card, CardContent, Checkbox, Container, FormControlLabel, Grid, Paper, Stack, Typography } from '@mui/material';
import { getItem, updateItem } from '../../services/api.svs';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PlaceIcon from '@mui/icons-material/Place';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";


const useStyles = makeStyles({
  textField: {
    '& .MuiInputLabel-root': {
      color: '#000', // Default label color
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#86c350', // Color when focused
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: '#f44336', // Color when error state
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#86c350',
        borderWidth: '2px'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#86c350',
        borderWidth: '2x'
      },
      '&.Mui-focused:hover fieldset': {
        borderColor: '#86c350',
        borderWidth: '2px'
      },
    },
  },
});

const ProfileHeadings = styled('h4')({
  color: "#000",
  textAlign: "center",
  fontFamily: "Righteous",
  fontSize: "2rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "4vh auto"
})

const AddButton = styled(Button)({
  backgroundColor: "#7ACA33",
  color: "#000",
  fontFamily: "Nova Round",
  fontSize: "1.5rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  flexShrink: 0,
  borderRadius: "10px",
  padding: 10,
  margin: "auto",
  textAlign: "center",
  '&:hover': {
    backgroundColor: '#4EA908',
  }
})

export function Protected() {

  // Regular expression for validating phone numbers (supports various formats)
  const phoneRegex = /^(?:(?:\+|00)([1-9]\d{0,2}))?([-\s./0-9]*\d[-\s./0-9]*)$/;

  // Regular expression for validating website URLs (supports HTTP and HTTPS)
  const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
  // Email regref
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const { route } = useAuthenticator((context) => [context.route]);
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")
  const [user, setUser] = useState<any>({})
  const [userBusinesses, setUserBusinesses] = useState<any>([])
  const [isEdited, setIsEdited] = useState(false);


  useEffect(() => {
    const getData = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem(`client`, token).then(resp => {
        console.log("Got back", resp.data)
        setUser(resp.data);
        getItem(`business/own`, token).then(resp => {
          console.log("Got back", resp.data)
          setUserBusinesses(resp.data);
          setIsLoaded(true)
        }).catch(resp => {
          console.log("None found")
          console.log(resp)
          setIsLoaded(true)
        })
      }).catch(resp => {
        console.log("None found")
        console.log(resp)
        setIsLoaded(true)
      })

    }
    getData()
  }, [])

  const [errors, setErrors] = useState({
    phone_number: '',
    email: '',
  });

  const handleChange = (event: any) => {
    const { name, type, checked, value } = event.target;


    let errorMessage = '';


    // Perform validation based on the field name
    if (name === 'phone_number') {
      if (!value.match(phoneRegex)) {
        errorMessage = 'Invalid phone number';
      }
    } else if (name === 'email') {
      if (!value.match(emailRegex)) {
        errorMessage = 'Invalid email';
      }
    }
    if (type === 'checkbox') {
      console.log(`Chaning ${name} to ${checked}`)
      setUser({ ...user, [name]: checked });
    } else {
      console.log(`Chaning ${name} to ${value}`)
      setUser({ ...user, [name]: value });
    }
    // Update the form state and errors

    setErrors({ ...errors, [name]: errorMessage });
    setIsEdited(true);
  };

  const handleSave = async () => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      updateItem(`client`, user,  token).then(resp => {
        console.log("Got back", resp.data)
        setUser(resp.data);
        
      }).catch(error => {
        console.log(error)
        setIsLoaded(true)
      })
    setIsEdited(false);
  };

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container sx={{ backgroundColor: "#f6f6f8" }}>
            <Grid container justifyContent="center" mt={20} mx={8}>
              <Grid item xs={12} md={12}>
                <Paper elevation={8} style={{ padding: '20px', margin: 'auto', borderRadius: '2vh' }}>
                  <ProfileHeadings>User Profile</ProfileHeadings>
                  <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                    <Stack spacing={2} direction="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Avatar sx={{ backgroundColor: '#86c350' }}>CP</Avatar>
                    </Stack>
                  </Box>
                  <Box component="form" noValidate autoComplete="off" sx={{ width: '80%', margin: '3vh auto' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-input"
                          label="First Name"
                          type="text"
                          name="first_name"
                          className={classes.textField}
                          value={user.first_name || ""}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-input"
                          label="Last Name"
                          type="text"
                          name="last_name"
                          className={classes.textField}
                          value={user.last_name || ""}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-input"
                          label="Phone Number"
                          type="text"
                          name="phone_number"
                          className={classes.textField}
                          value={user.phone_number || ""}
                          error={errors.phone_number !== ''}
                          helperText={errors.phone_number}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-input"
                          label="Email"
                          type="email"
                          name="email"
                          className={classes.textField}
                          value={user.email || ""}
                          error={errors.email !== ''}
                          helperText={errors.email}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={user.news_letter || false}
                              onChange={handleChange}
                              name="news_letter"
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  color: '#7ACA33',
                                },
                              }}
                            />
                          }
                          label="Subscribe to Newsletter"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={<Checkbox checked={user.notifications || false} onChange={handleChange} name="notifications"
                            sx={{
                              '& .MuiSvgIcon-root': {
                                color: '#7ACA33',
                              },
                            }}
                          />}
                          label="Receive Notifications"
                        />
                      </Grid>
                      {isEdited && (
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                          <AddButton onClick={handleSave}>Save</AddButton>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>

            {/* Company cards */}
            {userBusinesses.length >= 1 && (
              <>
                <Grid mt={10} mx={8} sx={{ width: "100%" }}>
                  <Paper elevation={8} style={{ padding: '20px', margin: 'auto', minHeight: "60vh", borderRadius: "1vh" }}>
                    <ProfileHeadings>Your businesses</ProfileHeadings>
                    <Grid container spacing={2}>
                      {/* Grid item for the card */}
                      <Grid item xs={12} sm={4}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Stack spacing={2} direction="row" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <Avatar sx={{ backgroundColor: "#86c350", width: 64, height: 64 }}>@</Avatar>
                            </Stack>
                            <Box
                              component="form"
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                flexDirection: "column",
                                gap: 2,
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <Typography variant="body1">Business Name: Example Business</Typography>
                              <Typography variant="body1">Phone: +123456789</Typography>
                              <Typography variant="body1">Email: example@example.com</Typography>
                              <Typography variant="body1">CRN: 1234567890</Typography>
                              <Typography variant="body1">Contact: +123456789</Typography>
                            </Box>
                            <Box sx={{ margin: "auto", textAlign: "center" }}>
                              <Link to="/business/test" style={{ textDecoration: "none" }}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"

                                  sx={{
                                    backgroundColor: '#7ACA33',
                                    color: '#000',
                                    fontFamily: 'Nova Round',
                                    fontSize: '1.2rem',
                                    fontWeight: 400,
                                    lineHeight: 'normal',
                                    borderRadius: '10px',
                                    padding: 2,
                                    margin: '4vh auto',
                                    '&:hover': {
                                      backgroundColor: '#4EA908'
                                    }
                                  }}

                                >
                                  View
                                </Button>
                              </Link>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      {/* Other grid items */}
                    </Grid>
                  </Paper>
                </Grid>
              </>
            )}





            <Box sx={{ margin: "8vh auto" }}>

              <Link to="/addBusiness" style={{ textDecoration: "none" }}>
                {userBusinesses.length >= 1 ? (
                  <AddButton>

                    Add another business
                  </AddButton>
                ) : (
                  <AddButton>

                    Add business
                  </AddButton>
                )}

              </Link>
            </Box>

          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}>
            <h3>Getting your profile ready</h3>
            <br/>
            <SyncLoader color="#7ACA33" /></div>

        </div>
      )}
    </>


  );
}


import { useState, useEffect } from 'react'
import { Product, Quote } from '../../data/models/general'
import { Button, Card, Container, Grid, Input, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import { initialProduct, initialQuote } from '../../data/models/initialData';
import "react-datepicker/dist/react-datepicker.css";
import { getItem, postAuthItem } from '../../services/api.svs';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/material/styles";

const useStyles = makeStyles({
    textField: {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#86c350',
          borderWidth: '2px'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#86c350',
          borderWidth: '2px'
        },
        '&.Mui-focused:hover fieldset': {
          borderColor: '#86c350',
          borderWidth: '2px'
        },
      },
      '& .MuiInputLabel-root': {
        color: '#000',
        '&.Mui-focused': {
          color: '#86c350'
        },
        '&.Mui-focused:hover': {
          color: '#86c350'
        }
      }
    },
});


const QuoteHeading = styled('h4')({
    color: "#000",
    textAlign: "center",
    fontFamily: "Righteous",
    fontSize: "2.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
    margin: "2vh auto"
  })

const QuoteSubHeading = styled('h5')({
    color: "#000",
    fontFamily: "Righteous",
    fontSize: "1.5rem",
    fontStyle: "normal",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "auto",
    textDecoration: "underline",
        '@media only screen and (max-width: 1000px)': {
            textAlign: 'center'
        }
})

const QuoteText = styled('h6')({
    color: "#000",
    fontFamily: "Rounded Mplus 1c",
    fontSize: "1.3rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "left",
    margin: "0.5vh auto 0vh auto"
})

const RequestButton = styled(Button)({
    backgroundColor: "#7ACA33",
    color: "#000",
    fontFamily: "Nova Round",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    flexShrink: 0,
    borderRadius: "10px",
    padding: 10,
    margin: "auto",
    textAlign: "center",
    '&:hover': {
      backgroundColor: '#4EA908',
    },
})

export function QuoteFunction() {
    const classes = useStyles();
    const [quote, setQuote] = useState<Quote>(initialQuote)
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([])
    const [products, setProducts] = useState<Product[]>([])

    const { user } = useAuthenticator((context) => [

        context.user
      ]);

    const QuoteDate = new Date().toLocaleDateString()

    const handleChange = (title: string, value: any) => {
        if (title === "name") {
            setQuote({ ...quote, name: value })
        } else if (title === "email_to") {
            setQuote({ ...quote, email_to: value })
        } else if (title === "address") {
            setQuote({ ...quote, address: value })
        } else if (title === "address2") {
            setQuote({ ...quote, address2: value })
        } else if (title === "notes") {
            setQuote({ ...quote, notes: [{
                email: "user",
                note: value
            }] })
        }
    }

    const handleProductChange = (index: number, name: keyof Product, value: any) => {
        console.log(index, name, value);
        const updatedProducts = selectedProducts.map((product, i) => {
            console.log("Checking i in index:",i,index)
          if (i === index) {
            console.log("Lets update...", name, value)
            const newProduct = { ...product };
      
            if (name === 'product_code') {
              newProduct.description = value;
      
              products.forEach((product) => {
                if (product.description === value) {
                  newProduct.product_code = product.product_code;
                }
              });
            } else {
              newProduct.quantity = Number(value);;
            }
      
            return newProduct;
          }
      
          return product;
        });
      
        console.log("Your list", updatedProducts)
        const updatedQuote = { ...quote, products: updatedProducts };
        setSelectedProducts(updatedProducts);
        console.log("Setting...", updatedQuote)
        setQuote(updatedQuote);
      };

    const handleSend = async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        console.log("Sending",quote)
        postAuthItem('quote', quote, token).then((resp: any) => {
            console.log(resp)
            let state = "success"
        }).catch((e: any) => {
            let state = "danger"
            console.log('failed with error', e);
        })
    }

    const handleRemove = (i: number) => {
        const products = quote.products.filter((product:any, index:any) => index !== i)

        setQuote({ ...quote, products })
    }

    const handleAdd = () => {
        const newProduct = { ...initialProduct };
        const updatedProducts = [...selectedProducts, newProduct];

        console.log("Adding,", updatedProducts)
        setSelectedProducts(updatedProducts);
        setQuote({ ...quote, products: updatedProducts });
    };

    useEffect(() => {
        const getData = async () => {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken()
            getItem('client', token).then(resp => {
                const user_id = resp.data.uuid

                setQuote({ ...quote, user_id: user_id })
            })
            console.log("Getting products")
            getItem('product', token).then(resp => {
                setProducts(resp.data)
            }).catch(resp => {
                console.log("Error:", resp)
                setProducts([])
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
            })
        }
        getData()
    }, [])

    return (
            <Grid mt={8} container sx={{ backgroundColor: "#f6f6f8" }}>
                <Grid mt={20} sx={{ width: {xs: "40%", lg: "100%"}, margin: {xs: 5, md: 8} }}>
                    <Paper elevation={8} style={{ padding: '20px', margin: 'auto', borderRadius: "1vh" }}>
                        <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: "center" }}>
                                    <Grid item sx={{ margin: '3%' }}>
                                        <QuoteHeading>REQUEST QUOTE</QuoteHeading>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ justifyContent: "left" }}>
                                    <Grid item xs={12} sx={{ marginLeft: "3%", marginBottom: "1%" }}>
                                        <QuoteSubHeading>Billing Info:</QuoteSubHeading>
                                    </Grid>
                                    
                                <Grid item sx={{ display: "flex", flexDirection: "column"}}>
                                    <Grid item xs={11} sx={{ margin: {xs: "1vh auto", lg: "1vh auto 2vh 20%"} }}>
                                        <TextField
                                            variant="standard"
                                            placeholder="Name"
                                            onChange={(e) => handleChange("name", e.target.value)}
                                        />
                                        
                                    </Grid>
                                    
                                    <Grid item xs={11} sx={{ margin: {xs: "1vh auto", lg: "1% auto 2vh 20%"} }}>
                                        <TextField
                                            variant="standard"
                                            placeholder="Email"
                                            type='email_to'
                                            onChange={(e) => handleChange("email_to", e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={11} sx={{ margin: {xs: "1vh auto", lg: "1% auto 2vh 20%"} }}>
                                        <TextField
                                            variant="standard"
                                            placeholder="Street Address"
                                            onChange={(e) => handleChange("address", e.target.value)}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={11} sx={{ margin: {xs: "1vh auto", lg: "1% auto 1vh 20%"} }}>
                                        <TextField
                                            variant="standard"
                                            placeholder="City, Code"
                                            onChange={(e) => handleChange("address2", e.target.value)}
                                        />
                                    </Grid>
                                </Grid>

                                    <Grid container sx={{ justifyContent: "right" }}>
                                        <Grid item xs={12} sx={{ margin: {xs: "2vh auto", md: "1% 10%" }, textAlign: {xs: "center", md: "right"} }}>
                                            <Typography variant='h5' sx={{ fontFamily: "Rounded Mplus 1c", fontWeight: 500, margin: { xs: "4vh auto 2vh auto", md: "auto" }, fontSize: "1.5rem" }}> Date</Typography>
                                        </Grid>

                                        <Grid item xs={12} sx={{ margin: {xs: "2vh auto", md: "1% 10%" }, textAlign: {xs: "center", md: "right"} }}>
                                            <Typography variant='h6' sx={{ fontWeight: 400, fontSize: "1.3rem", margin: "1vh auto"  }}>{QuoteDate}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                {selectedProducts.map((product, i) => {
                                    return (
                                        <Grid container sx={{ display: "flex", justifyContent: "center", alignContent: "center", flexFlow: {xs: "column", lg: "row"}, textAlign: "center", margin: "2% auto", }} key={i}>

                                            <Grid item xs={4} sx={{ margin: "1vh auto" }}>
                                                <InputLabel id="demo-multiple-name-label" sx={{ color: "#000", fontSize: "1.5rem" }}>{product.description}</InputLabel>

                                                <Select variant='standard'
                                                    defaultValue={product.description}
                                                    onChange={(value) => handleProductChange(i, 'product_code', value.target.value)}
                                                >
                                                    {products.map((prod, j) => {
                                                        return <MenuItem key={j} value={prod.description} sx={{ color: "#000", fontSize: "1.2rem" }}>{prod.description}</MenuItem>
                                                    })}
                                                </Select>
                                            </Grid>

                                            <Grid item xs={4} sx={{ margin: "1vh auto" }}>
                                                <InputLabel id="demo-multiple-name-label" sx={{ color: "#000", fontSize: "1.5rem" }}>Quantity</InputLabel>

                                                <Input
                                                    sx={{ width: "1em" }}
                                                    onChange={(value: any) => handleProductChange(i, 'quantity', value.target.value)}
                                                />
                                            </Grid>

                                            <Grid item xs={2}></Grid>

                                            <Grid item xs={2} sx={{ margin: "auto" }}>
                                                <IconButton aria-label="delete" color="warning" onClick={() => handleRemove(i)}>
                                                    <RemoveCircleIcon sx={{ fontSize: "2rem"}}/>
                                                </IconButton>
                                            </Grid>

                                        </Grid>
                                    )
                                })}

                                <Grid container sx={{ justifyContent: "left", marginLeft: "2vw" }}>
                                    <Grid item xs={6} sx={{ margin: "2% 0" }}>
                                        <IconButton aria-label="add" color="secondary" onClick={handleAdd}>
                                            <AddCircleIcon sx={{ fontSize: "2rem"}} />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ justifyContent: "center" }}>
                                    <Grid item xs={8} sx={{ marginBottom: "1%", width: 500, maxWidth: '100%' }}>
                                        <TextField fullWidth multiline
                                            rows={12} label="Notes" onChange={(e) => handleChange("notes", e.target.value)} className={classes.textField}  />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ justifyContent: "right" }}>
                                    <Grid item xs={12} sx={{ margin: "2vh auto", textAlign: "center" }}>
                                        <RequestButton>
                                            Request Quote
                                        </RequestButton>
                                    </Grid>
                                </Grid>

                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
    )
}
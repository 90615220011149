import { Modal, useMediaQuery, useTheme, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography, Marker, Graticule } from "react-simple-maps";
import { markers } from "./project_data";
import { useNavigate } from "react-router-dom";

export function ProjectMap() {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);

  const [projectList, setProjectList] = useState([]);
  const [countryCode, setCountryCode] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const geoUrl = "/world.json";

  const handleCountryClick = (data) => {
    console.log("country");
    console.log(data);

    handleOpen()
  };

  const handleMouseEnter = (e, geo) => {
    e.preventDefault()
    if (countriesIn.includes(geo.id)) {
      handleCountryClick(geo);
      setCountryCode(geo.id)
      const filteredMarkers = markers.filter(marker => marker.country_code === geo.id);
      setProjectList(filteredMarkers)

    }
  };

  const countriesIn = ["ZAF", "MOZ", "EGY"];

  const handleViewMore = () => {
    if (countryCode) {
      navigate(`/projects/${countryCode}`);
    }
  };

  return (
    <>
      <div className="map-container">
        <ComposableMap style={{width: "100%"}}>
          {/* <Graticule stroke="#F53" /> */}
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(e) => handleMouseEnter(e, geo)}
                  style={{
                    default: {
                      fill: countriesIn.includes(geo.id) ? "#86C350" : "#666666", // Conditional fill color
                    },
                    hover: {
                      fill: countriesIn.includes(geo.id) ? "#86C350" : "#666666",
                    },
                    pressed: {
                      fill: countriesIn.includes(geo.id) ? "#86C350" : "#666666",
                    },
                  }}
                />
              ))
            }
          </Geographies>
          
        </ComposableMap>
        {open && (
          <Box
            sx={{
              padding: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 1,
              width: isMediumScreen ? '100%' : 'auto',
              maxWidth: isMediumScreen ? '100%' : '500px',
              height: isMediumScreen ? '100%' : 'auto',
              position: 'relative', // To position content inside
              margin: isMediumScreen ? 'auto' : '0',
            }}
          >
            <h2>Projects</h2>
            <p>Below are some ouf our top projects we did in this country</p>

            {projectList.map((project) => (
              <p key={project.id}>{project.name}</p>
            ))}
            <Button sx={{ backgroundColor: "#86C350" }} onClick={handleViewMore}>View more</Button>
          </Box>
        )}
      </div>


    </>
  );
}

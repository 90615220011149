// components/Home.js
//CSS
import "./ablutions.css";


//IMAGES
import ablutionsBackground from "../../assets/woodstockie/ablutions/31442c41-b93d-4b99-9a6a-51bf20c2722e.webp";
import ablutionsImage1 from '../../assets/images/ablutionsImage1.png';
import ablutionsImage2 from '../../assets/images/ablutionsImage2.png';
import ablutionsImage3 from '../../assets/images/ablutionsImage3.png';
import ablutionsImage4 from '../../assets/images/ablutionsImage4.png';
import ablutionsImage5 from '../../assets/images/ablutionsImage5.png';
import ablutionsImage6 from '../../assets/images/ablutionsImage6.png';


//MUI COMPONENTS
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import { css } from '@emotion/react';
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styled from '@emotion/styled';
import { Typography } from "@mui/material";


//OTHER COMPONENTS
import * as React from "react";
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import Maps from "../../components/maps/Maps";
import { SyncLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import SwipeableTextMobileStepper from '../home/carousel';
import { useEffect, useState } from "react";


const StyledPaper = styled(Paper)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#000000B2',
  textAlign: 'left',
  boxSizing: 'border-box',
  color: '#ffffff',
  wordWrap: 'break-word',
  fontWeight: 400
});

const StyledContainer = styled(Paper)({
  position: 'relative',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url('${ablutionsBackground}')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'grayscale(100%)',
  padding: 0
});


const BannerText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'Righteous',
  fontSize: '100px',
  fontWeight: 400,
  margin: '0 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '2.5rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '3rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const BannerSubText = styled('p')({
  color: '#ffffff', 
  fontFamily: 'M PLUS Rounded 1c',
  fontSize: '40px',
  fontWeight: 400,
  margin: '1vh 6vw',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '100%',

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.25rem',
    margin: 'auto',
    textAlign: 'center'
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '2rem',
    margin: 'auto',
    textAlign: 'center',
  },
});

const LandingSectionStyles = styled.div`
  background-image: url(${ablutionsBackground});
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat; 
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 1vh auto;
  filter: grayscale(100%);
`;

const PageHeading = styled('h1')({
  color: "#4EA908",
  fontFamily: "Righteous",
  fontSize: "40px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "49.67px",
  margin: "4vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.5rem',
    margin: 'auto',
    textAlign: 'center',
    padding: "0 2vw"
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '1.8rem',
    margin: 'auto',
    textAlign: 'center',
    padding: "0 2vw"
  },
})

const InfoHeading = styled('h6')({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 800,
  margin: "4vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.375rem',
    margin: 'auto',
    textAlign: 'center'
  },
})

const InfoText = styled('p')({
  color: "#000000",
  fontFamily: "Rounded Mplus 1c",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 400,
  margin: "4vh auto",
  lineHeight: "35px",

  '@media only screen and (max-width: 600px)': {
    fontSize: '1.2rem',
  },
})

const QuoteButton = styled(Button)({
  backgroundColor: "#4EA908",
  padding: '16px 32px',
  fontSize: '3.125rem',
  borderRadius: "80px",
  color: "#000000",
  fontFamily: "Righteous",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "62px",
  textAlign: "center",
  margin: "4vh auto",
  textTransform: "capitalize",
  '&:hover': {
    backgroundColor: '#4EA908',
},

'@media only screen and (max-width: 600px)': {
  fontSize: '2rem',
  margin: '2vh auto',
  padding: '1vh 4vw'
},

'@media only screen and (min-width: 601px) and (max-width: 768px)': {
  fontSize: '2rem',
  margin: '2vh auto',
  padding: '1vh 4vw'
},
})

const EndText = styled('h1')({
  color: "#000000",
  fontFamily: "Righteous",
  fontSize: "36px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "44px",
  margin: "4vh auto",

  '@media only screen and (max-width: 600px)': {
    fontSize: "1.2rem",
    margin: "auto",
    textAlign: "center",
    padding: "2vh 2vw"
  },

  '@media only screen and (min-width: 601px) and (max-width: 768px)': {
    fontSize: '1.4rem',
    margin: '2vh auto',
    textAlign: 'center',
    padding: "0 2vw"
  },
})

const ablutionsImages = [
  { imgPath: ablutionsImage1 },
  { imgPath: ablutionsImage2 },
  { imgPath: ablutionsImage3 },
  { imgPath: ablutionsImage4 },
  { imgPath: ablutionsImage5 },
  { imgPath: ablutionsImage6 },
];

export function Ablutions() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
   
    
      <Grid container>
        <StyledContainer>
          <StyledPaper>
            <BannerText>ABLUTIONS</BannerText>
            <BannerSubText>Prioritizing hygiene and convenience</BannerSubText>
          </StyledPaper>
        </StyledContainer>

        <Grid container>
          <PageHeading>
            Our custom designed ablutions ensure comfort. functionality and durability, through:
          </PageHeading>
        </Grid>

        <Grid container spacing={3} sx={{ margin: {xs: "auto", md: "inital"}}}>
          {/* First Column */}
          <Grid item xs={11} md={6}>
              <InfoHeading>Customized designs </InfoHeading>
              <InfoText>
                Create ablution facilities tailored to your specific needs, whether for events, construction sites, or public areas
              </InfoText>
          </Grid>

          {/* Second Column */}
          <Grid item xs={11} md={6}>
              <InfoHeading>Versatile applications</InfoHeading>
              <InfoText>
                Our ablutions are suitable for a wide range of settings, providing comfort and convenience wherever needed.
              </InfoText>
          </Grid>

          
          {/* Row 2 */}
          <Grid item xs={11} md={6}>
              <InfoHeading>Durable and weather- resistant</InfoHeading>
              <InfoText>
                Our ablutions are built to withstand harsh conditions while maintaining structural integrity
              </InfoText>
          </Grid>

          <Grid item xs={11} md={6}>
              <InfoHeading>Sanitary solutions</InfoHeading>
              <InfoText>
                Incorporate modern fixtures and materials to ensure a clean and hygienic environment for users
              </InfoText>
          </Grid>

          <Grid item xs={11} md={6} sx={{ margin: "intial 2vw"}}>
              <InfoHeading>Efficient layouts</InfoHeading>              
              <InfoText>
                Optimize space with thoughtfully planned layouts that accommodate multiple facilities in a compact space
              </InfoText>
          </Grid>

          {/* Row 3 */}
          <Grid item xs={11} md={6}>
              <InfoHeading>Cost-effective designs</InfoHeading>
              <InfoText>
                Save on construction costs without compromising on quality and functionality
              </InfoText>
          </Grid>
        </Grid>

        <Grid container>
          <SwipeableTextMobileStepper images={ablutionsImages} />;
        </Grid>

        <Grid container>
          <EndText>
            Elevate your ablution facilities with container solutions that prioritize your comfort, durability and hygiene
          </EndText>
        </Grid>

        <Grid container>
          <QuoteButton>Get a Quote</QuoteButton>
        </Grid>
      </Grid>

    </>
  );
}

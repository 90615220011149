// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import * as React from 'react';
import './400.css';
import NavBar from "../../layout/navbar/Navbar";
import { Grid } from '@mui/material';

export function NotFoundPage() {
  return (
    <>
      <Grid container sx={{ backgroundColor: "fff", margin: 0}}>
        <Grid container className="notFoundNav">
          <NavBar />
        </Grid>
    
        <Grid container sx={{display: "flex", justifyContent: "center", marginTop: "10%"}}>
          <Typography className='notf' variant="h4"  sx={{fontSize: "8rem", margin: "auto"}}>
            404
          </Typography>
        </Grid>

        <Typography variant="h6" sx={{margin: "auto", fontFamily: "Rounded Mplus 1c", fontSize: "2rem", fontWeight: 400 }}>This page cannot be found.</Typography>
      </Grid>

    </>
  );
}
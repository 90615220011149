// components/Home.js
import { SyncLoader } from "react-spinners";
import { useEffect, useRef, useState } from "react";

import * as React from "react";
import { autoPlay } from 'react-swipeable-views-utils';
import { getItem } from "../../services/api.svs";
import { Heading } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import Maps from "../../components/maps/Maps";
import NavBar from "../../layout/navbar/Navbar";
import SwipeableTextMobileStepper from './carousel';
import SwipeableViews from 'react-swipeable-views';


//CSS
import "./home.css";


//ICONS
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkIcon from '@mui/icons-material/Work';
import trainingRoomIcon from "../../assets/greenIcons/trainingRoomIcon.svg";
import aluminiumIcon from "../../assets/greenIcons/aluminiumIcon.svg";
import ablutionsIcon from "../../assets/greenIcons/ablutionsIcon.svg";
import controlRoomIcon from "../../assets/greenIcons/controlRoomIcon.svg";
import modernHomeIcon from "../../assets/greenIcons/modernHomeIcon.svg";
import officeIcon from "../../assets/greenIcons/officeIcon.svg";
import restaurantIcon from "../../assets/greenIcons/restaurantIcon.svg";
import storageUnitIcon from "../../assets/greenIcons/storageUnitIcon.svg";
import containerIcon from "../../assets/greenIcons/containerIcon.svg";


//IMAGES
import companyLogo from "../../assets/images/woodstockieLogo.jpeg";
import image1 from "../../assets/images/woodstockieBackground.png";
import image2 from "../../assets/images/backgroundImage2.png";
import homeImage1 from "../../assets/woodstockie/offices/IMG_1203.jpg"
import homeImage2 from "../../assets/woodstockie/pizza/ec9dba35-e1d6-44fb-9a7d-0936107f77bf.webp";
import homeImage3 from "../../assets/woodstockie/classroom/IMG_8857.webp";
import homeImage4 from "../../assets/woodstockie/control_room/IMG_4808.webp";
import homeImage5 from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-23.jpg";
import homeImage6 from "../../assets/woodstockie/store/PHOTO-2022-03-06-07-38-23.jpg";
import homeImage7 from "../../assets/woodstockie/ablutions/IMG_1197.webp";


//MUI COMPONENTS
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from "@mui/material/Box";
import { Button, TextField, useMediaQuery } from "@mui/material";
import Card from "@mui/material/Card";
import { CardActionArea, CardActions } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';



const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '90vh',
    margin: "4vh 0"
  },
  backgroundImage: {
    width: '50%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  button: {
    backgroundColor: "#86C350",
    padding: '16px 32px',
    fontSize: '1.5rem',
    borderRadius: "40px",
    color: "#1E1E1E",
    fontFamily: "Nova Round",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    '&:hover': {
      backgroundColor: '#7BC24F',
    }
  },
}));

// const colors = [homeImage1, homeImage2, homeImage3, homeImage4, homeImage5, homeImage6, homeImage7];
// const delay = 2500;

const modernHomeImages = [
  { label: 'Offices', imgPath: homeImage1 },
  { label: 'Restaurants', imgPath: homeImage2 },
  { label: 'Training Rooms', imgPath: homeImage3 },
  { label: 'Control Rooms', imgPath: homeImage4 },
  { label: 'Modern Homes', imgPath: homeImage5 },
  { label: 'Storerooms', imgPath: homeImage6 },
  { label: 'Ablutions', imgPath: homeImage7 },
];

export function Home() {

  const handleClick = () => {
    const contactForm = document.getElementById('contact-form');

    if (contactForm) {
      contactForm.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const SendButton = styled(Button)({
    backgroundColor: "#7ACA33",
    color: "#000",
    fontFamily: "Nova Round",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    flexShrink: 0,
    borderRadius: "10px",
    '&:hover': {
      backgroundColor: '#4EA908',
    }
  })

  const MyHeading = styled('h1')({
    color: "#000",
    fontFamily: "Righteous",
    fontSize: "3rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0 auto",

    '@media only screen and (max-width: 600px)': {
      fontSize: '1.5rem',
      margin: 'auto',
      textAlign: 'center'
    },
  });

  const GetInTouch = styled('h4')({
    color: "#000",
    textAlign: "center",
    fontFamily: "Righteous",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "4vh 0vw 2vh 2vw"
  })

  const GetInTouchHeadings = styled('h5')({
    color: "#000",
    fontFamily: "Nova Round",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",

    '@media only screen and (max-width: 600px)': {
      textAlign: 'center',
      marginBottom: '2vh'
    }
  })

  const VisitUsHeadings = styled('h5')({
    color: "#000",
    fontFamily: "Nova Round",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: 'center',

    '@media only screen and (max-width: 600px)': {
      marginTop: '4vh',
    }
  })

  const AboutHeading = styled(Typography)({
    color: "#000",
    fontFamily: "Nova Round",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "2vh 0"
  })

  const ServicesText = styled(Typography)({
    color: "#D9D9D9",
    fontFamily: "Rounded Mplus 1c",
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "1vh auto"
  })

  const TextfieldLabels = styled(Typography)({
    color: "#000",
    fontFamily: "Rounded Mplus 1c",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  })

  const AboutText = styled(Typography)({
    fontFamily: "Rounded Mplus 1c",
    fontSize: "1.5rem",
    textAlign: "left",
  })


  const CompanyLogoStyling = styled('img')({
    padding: "1vh",
    maxWidth: '400px',
    height: 'auto',
    border: "0.4vh solid #000",
    borderRadius: "2%",
    display: "flex",
    justifyContent: "flex-start"
  })

  const SubHeading = styled('h6')({
    color: "#4EA908",
    fontFamily: "Rounded Mplus 1c",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "2vh 0",

    '@media only screen and (max-width: 600px)': {
      fontSize: '1.2rem',
      margin: '2vh auto',
      textAlign: 'center'
    }
  })

  const FAQHeading = styled('h4')({
    color: "#000",
    fontFamily: "Righteous",
    fontSize: "2.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "left"
  })

  const ContactText = styled('h6')({
    color: "#000",
    fontFamily: "Rounded Mplus 1c",
    fontSize: "1.3rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "left",
    width: "55%",
    marginTop: "1vh",

    '@media only screen and (max-width: 600px)': {
      width: "70%",
      marginBottom: "4vh"
    }
  })

  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${image1})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',

    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });
  
  const CenteredButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#86C350",
    padding: '16px 32px',
    fontSize: '1.5rem',
    borderRadius: "20px",
    fontFamily: "Nova Round",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "#1E1E1E",
    '&:hover': {
      backgroundColor: '#4EA908',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(1.5, 3),
    },
  }));


  const [isLoaded, setIsLoaded] = useState(true);
  // const [notificationID, setNotificationID] = useState("");
  var i = 0;
  var images: string | any[] = [];
  var time = 3000;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  interface Item{
    text: string;
    icon: string;
    path: string;
  }

  const structureTypes: Item[] = [
    {text: 'Control Rooms', icon: controlRoomIcon, path: '/control_room'},
    {text: 'Ablutions', icon: ablutionsIcon, path: '/ablutions'},
    {text: 'Training rooms', icon: trainingRoomIcon, path: '/classrooms'},
    {text: 'Aluminium', icon: aluminiumIcon, path: '/aluminium'},
    {text: 'Containers', icon: containerIcon, path: '/containers'},
    {text: 'Storage Units', icon: storageUnitIcon, path: '/storage'},
    {text: 'Restaurants', icon: restaurantIcon, path: '/restaurants'},
    {text: 'Offices', icon: officeIcon, path: '/offices'},
    {text: 'Modern Homes', icon: modernHomeIcon, path: '/modernHomes'},
  ]
  return (

    <>
      {isLoaded ? (
        <>
          <Grid container sx={{ backgroundColor: "#D9D9D9", boxSizing: "border-box", width: {xs: "100vw", lg: "100%"} }}>

            <BackgroundContainer>
              <CenteredButton variant="contained" onClick={handleClick}>
                Click Here!
              </CenteredButton>
            </BackgroundContainer>

            <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <MyHeading>
                CUSTOM SHIPPING CONTAINERS
              </MyHeading>

              <SubHeading>
                SA's Leaders in modular solutions
              </SubHeading>
            </Grid>


            <Grid container className="servicesIcons" sx={{ backgroundColor: "#444444", display: "flex", justifyContent: {xs: "center", sm: "initial"}, padding: {xs: "initial", sm: "2vw"} }}>
              {isMobile ? (
                <>
                  {/* Styles on phones */}
                  {structureTypes.slice(0, 8).map((item, index) => (
                    <Grid item xs={4} key={index} m={2}>
                      <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Grid container direction="column" alignItems="center">
                          <img src={item.icon} alt={item.text} width={"50px"} height={"50px"} />
                          <ServicesText sx={{ textAlign: "center"}}>{item.text}</ServicesText>
                        </Grid>
                      </Link>
                    </Grid>
                  ))}

                  {/* Centering the last item */}
                  <Grid item xs={10} m={2} style={{ textAlign: 'center' }}>
                    <Link to={structureTypes[8].path} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Grid container direction="column" alignItems="center">
                        <img src={structureTypes[8].icon} alt={structureTypes[8].text} width={"50px"} height={"50px"} />
                        <ServicesText>{structureTypes[8].text}</ServicesText>
                      </Grid>
                    </Link>
                  </Grid>
                </>
              ) : (
                <>
                  {/* Styles on devices bigger than phones */}
                  {/* First row with 4 items */}
                  <Grid item container xs={12} spacing={2}>
                    {structureTypes.slice(0, 4).map((item, index) => (
                      <Grid item xs={3} key={index}>
                        <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                          <Grid container direction="column" alignItems="center">
                            <img src={item.icon} alt={item.text} width={"50px"} height={"50px"} />
                            <ServicesText>{item.text}</ServicesText>
                          </Grid>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>

                  {/* The second row with 5 items */}
                  <Grid container mt={3} xs={12} spacing={2}>
                    {structureTypes.slice(4).map((item, index) => (
                      <Grid item xs={2.4} key={index}>
                        <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                          <Grid container direction="column" alignItems="center">
                            <img src={item.icon} alt={item.text} width={"50px"} height={"50px"} />
                            <ServicesText>{item.text}</ServicesText>
                          </Grid>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container>
              <SwipeableTextMobileStepper images={modernHomeImages} />;
            </Grid>

            <Grid container sx={{ background: "#86C350", display: "flex", justifyContent: "center" }}>

              <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                <AboutHeading>
                  ABOUT US
                </AboutHeading>
              </Grid>

              <Grid container sx={{ display: "grid", gridTemplateColumns: "1fr", padding: "4vh 0vw", margin: "auto", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }}>
                  <Grid item xs={12} md={6}>
                    <CompanyLogoStyling src={companyLogo} alt="Woodstock company logo" sx={{ width: { xs: "40%", sm: "100%" }, margin: { xs: "auto", md: "2vh 2vw 2vh 2vw" } }} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <AboutText sx={{ display: "flex", justifyContent: { xs: "center", sm: "center" }, width: { xs: "60%", sm: "80%" }, margin: { xs: "8vh auto", md: "2vh 3vw 2vh 3vw" } }}>
                      We are passionate to deliver outstanding quality container
                      conversions at the most affordable prices. Our dedicated
                      team has a passion for creating structures for various
                      configurations. We offer end to end solutions at the best
                      possible rates. Our passionate team of experts will ensure
                      you get the best value for money and a solution tailored to
                      your needs.
                    </AboutText>
                  </Grid>
                </Stack>

              </Grid>
            </Grid>

            <Grid container>
              <GetInTouch sx={{ margin: { xs: "2vh auto", lg: "2vh 2vw" } }}>GET IN TOUCH</GetInTouch>
            </Grid>

            <Grid container className="contact" sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, alignItems: "center", justifyItems: "center", my: 4 }}>

              <Grid container sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <GetInTouchHeadings sx={{ margin: "1vh auto", textAlign: "left" }}>
                  GET A <span>QUOTE</span>
                </GetInTouchHeadings>

                <ContactText sx={{ margin: "2vh auto", textAlign: "left" }}>
                  Contact us today for all your needs. We offer container conversions, aliminium windows -and doors, and storage units.
                </ContactText>
              </Grid>

              <Grid item>
                <VisitUsHeadings>
                  CALL <span>US</span>
                </VisitUsHeadings>

                <ContactText sx={{ margin: "1vh auto", textAlign: "center" }}>
                  082 414 2858
                </ContactText>

                <ContactText sx={{ margin: "1vh auto", textAlign: "center" }}>
                  083 280 9079
                </ContactText>

                <ContactText className="emailLinks">
                  <a href="mailto:corne@woodstockie.com">corne@woodstockie.com</a>
                </ContactText>

                <ContactText className="emailLinks">
                  <a href="mailto:sales1@woodstockie.com">sales1@woodstockie.com</a>
                </ContactText>
              </Grid>

            </Grid>

            <Grid container sx={{
              padding: "2vw", display: "grid",
              justifyContent: "space-around", gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }
            }}>



              <Grid container className="location" sx={{ display: "flex", flexFlow: "column", marginTop: { xs: "2vh", sm: 0 } }}>
                <VisitUsHeadings>
                  VISIT <span style={{ color: "#4EA908" }}>US</span>
                </VisitUsHeadings>

                <ContactText sx={{ margin: "1vh auto", textAlign: "center" }}>
                  Plot 161, Hallgate, Nigel, 1490
                </ContactText>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                padding: "2vw",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={8}
                sm={12}
                sx={{
                  marginBottom: "5vh",
                  marginTop: "2vh",
                  border: "0.5vh solid #4EA908",
                  padding: "1vh"
                }}>
                <Maps
                  lat={-26.430405}
                  lng={28.531670}
                  heigh={"60vh"}
                  width={"auto"}
                />
              </Grid>

            </Grid>

            <Grid container sx={{ display: "flex", justifyContent: "space-around", flexFlow: "row", alignItems: "center", margin: "6vw" }}>
              <Stack direction={{ xs: "column", md: "row" }}>
                <Grid container>
                  <FAQHeading sx={{ margin: { xs: "auto", md: "initial" } }}>
                    FREQUENTLY<br /> ASKED<br /> QUESTIONS.
                  </FAQHeading>
                </Grid>

                <Grid container>
                  <Container sx={{ margin: "auto" }}>
                    <div className='accordion-con'>
                      <Accordion sx={{ width: { xs: "80%", sm: "100%" }, margin: "1vh auto" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: "#000", backgroundColor: "#4EA908", borderRadius: "50%", padding: 0.1 }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6" className='accordion-header' sx={{
                            fontSize: { xs: 15, sm: 16, md: 18, lg: 20, xl: 20 }, color: "#000", fontFamily: "Rounded Mplus 1c",
                            fontStyle: "normal", fontWeight: 500, lineHeight: "normal"
                          }}>
                            How can container solutions benefit my business?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 17, xl: 19 } }}>
                            Container solutions offer remarkable versatility. From creating mobile offices to pop-up shops, they provide cost-effective and customizable spaces that adapt to your business needs.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion sx={{ width: { xs: "80%", sm: "100%" }, margin: "1vh auto" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: "#000", backgroundColor: "#4EA908", borderRadius: "50%", padding: 0.1 }} />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography variant="h6" className='accordion-header' sx={{
                            fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 }, color: "#000", fontFamily: "Rounded Mplus 1c",
                            fontStyle: "normal", fontWeight: 500, lineHeight: "normal", marginX: "0.05vh"
                          }}>
                            Can containers be customized to match my brand's aesthetic?
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                            Absolutely. Our container solutions are fully customizable. You can choose colors, branding, and interior layouts that align perfectly with your brand's identity.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion sx={{ width: { xs: "80%", sm: "100%" }, margin: "1vh auto" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: "#000", backgroundColor: "#4EA908", borderRadius: "50%", padding: 0.1 }} />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography variant="h6" className='accordion-header' sx={{
                            fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 }, color: "#000", fontFamily: "Rounded Mplus 1c",
                            fontStyle: "normal", fontWeight: 500, lineHeight: "normal"
                          }}>
                            Are container solutions eco-friendly?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                            Yes, container solutions are eco-friendly alternatives. They repurpose shipping containers, reducing waste and supporting sustainable construction practices.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>


                    </div>
                  </Container>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "70vh",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={{ marginTop: "25vh" }}>
            <h1>Loading your profile</h1>
            <SyncLoader color="#36d7b7" />
          </div>
        </div>
      )}
    </>
  );
}
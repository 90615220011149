import { Product, Quote } from "./general"

export const initialProduct: Product = {
    product_code: '001',
    description: 'Supply Pre-owned wind & water tight unit',
    quantity: 1
}
  
 export const initialQuote: Quote = {
  user_id: '',
    name: '',
    address: '',
    address2: '',
    email_to: '',
    products: [
      
    ],
    notes: [{
      email: "",
      note: ""
    }],
  }
// components/Protected.js
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { SyncLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import NavBar from "../../layout/navbar/Navbar";
import { Side } from '../../layout/sidebar/sidebar';
import { Grid } from '@mui/material';
import { List, ListItem, ListItemText, Typography, Link } from '@mui/material';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { QuoteFunction } from '../../components/quote/quote';

export function GenerateQuote() {

  const { route } = useAuthenticator((context) => [context.route]);
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setIsLoaded(true)


    }
    getData()
  }, [])

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container spacing={0}>

            <Grid item xs={12} sm={12} md={12}>
              <QuoteFunction />
            </Grid>

          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><SyncLoader color="#36d7b7" /></div>

        </div>
      )}
    </>


  );
}
// BannerContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const BannerContext = createContext();

export function BannerProvider({ children }) {
  const [banner, setBanner] = useState("test");

  useEffect(() => {
    async function fetchBanner() {
      // Replace with your API call
      const response = await fetch('/api/banners');
      const data = await response.json();
      setBanner(data.activeBanner); // Adjust based on your API response
    }
    
    fetchBanner();
  }, []);

  return (
    <BannerContext.Provider value={banner}>
      {children}
    </BannerContext.Provider>
  );
}

export function useBanner() {
  return useContext(BannerContext);
}

// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { SyncLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './faq.css';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Container from '@mui/material/Container';
import NavBar from "../../layout/navbar/Navbar";
import { Grid, Stack } from '@mui/material';
import { ReactComponent as SvgIcon } from '../../assets/images/FAQSvg2.svg'; // Replace 'icon.svg' with your SVG file
import { Paper } from "@mui/material"

export function FAQ() {
  const [isLoaded, setIsLoaded] = useState(true)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    // const getData = async () => {

    //   setNotificationID(Store.addNotification({
    //     title: "Wonderful!",
    //     message: "teodosii@react-notifications-component",
    //     type: "warning",
    //     insert: "top",
    //     container: "top-left",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     dismiss: {
    //       duration: 2000,
    //       onScreen: true
    //     }
    //   }));

    //   Store.removeNotification(notificationID)
    // await delay(1000);
    setIsLoaded(true)

    // }
    // getData()
  }, [])


  return (
    <>
      {isLoaded ? (
        <>
          <Grid container sx={{ backgroundColor: "#000000", margin: 0}}>
            <Grid container>
              <Stack direction={{xs: "column", sm: "row"}} sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "6vh auto" }}>
                <Grid item mt={10} xs={10} sm={5}>
                  <SvgIcon style={{ width: '100%', height: '80%' }} />
                </Grid>

                {/* FAQ Section */}
                <Grid item xs={10} sm={5}>
                    <div className='accordion-con'>
                      <Accordion sx={{ marginBottom: "1.5vh", backgroundColor: "#7ACA33" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: "#000000" }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6" className='accordion-header'
                            sx={{
                              fontSize: '1.375rem',
                              fontWeight: 800,
                              fontFamily: "M PLUS Rounded 1c",
                            }}>
                            How can container solutions benefit my business?
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: "1.25rem", fontWeight: 400, fontFamily: "M PLUS Rounded 1c !important" }}>
                            Container solutions offer remarkable versatility. From creating mobile offices to pop-up shops, they provide cost-effective and customizable spaces that adapt to your business needs.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion sx={{ marginBottom: "1.5vh", backgroundColor: "#7ACA33" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: "#000000" }} />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography variant="h6" className='accordion-header' sx={{ fontSize: "1.375rem", fontWeight: 800, fontFamily: "M PLUS Rounded 1c !important" }}>
                            Can containers be customized to match my brand's aesthetic?
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: "1.25rem", fontWeight: 400, fontFamily: "M PLUS Rounded 1c !important" }}>
                            Absolutely. Our container solutions are fully customizable. You can choose colors, branding, and interior layouts that align perfectly with your brand's identity.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion sx={{ marginBottom: "1.5vh", backgroundColor: "#7ACA33" }}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#000000" }} />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography variant="h6" className='accordion-header' sx={{ fontSize: "1.375rem", fontWeight: 800, fontFamily: "M PLUS Rounded 1c !important" }}>
                            Are container solutions eco-friendly?
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: "1.25rem", fontWeight: 400, fontFamily: "M PLUS Rounded 1c !important" }}>
                            Yes, container solutions are eco-friendly alternatives. They repurpose shipping containers, reducing waste and supporting sustainable construction practices.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                </Grid>
              </Stack>
              {/* SVG Section */}
             
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><SyncLoader color="#36d7b7" /></div>

        </div>


      )}
    </>


  );
}
